export default {
  whiteCard: {
    height: "auto",
    width: "90%",
    padding: 10,
    background: "#FFFFFF",
    margin: "20px",
    borderRadius: 12,
    fontFamily: "Ubuntu",
    boxShadow: `inset 0 0 15px rgba(55, 84, 170,0),
        inset 0 0 20px rgba(255, 255, 255,0),
        7px 7px 15px rgba(55, 84, 170,.15),
        -7px -7px 20px rgba(255, 255, 255,1),
        inset 0px 0px 4px rgba(255, 255, 255,.2)`,
  },
  typography32: {
    fontSize: 20,
    color: "#26184D",
    fontWeight: 600,
    fontFamily: "Ubuntu",
  },
  viewBtn: {
    fontSize: 20,
    color: "#26184D",
    fontWeight: 600,
    padding: "10px 20px",
    background: "#f0cdbd",
    borderRadius: 12,
    cursor: "pointer",
    fontFamily: "Ubuntu",
  },
  tableContainer: {
    height: "auto",
    borderRadius: 20,
    margin: "20px 0",
    //  width : "50%",
    //  margin : "10px auto"
  },

  typography14: {
    fontSize: 13,
    color: "#26184D",
    fontWeight: 400,
    fontFamily: "Ubuntu",
    fontStyle: "normal",
  },
  Btn: {
    textTransform: "capitalize",
    fontFamily: "Ubuntu",
    fontSize: 14,
    color: "#FFF",
    fontWeight: 500,
    background: "#E0632B",
    padding: "5px 25px",
    borderRadius: 6,
    border: "3px solid #E0632B",
    cursor: "pointer",
    "&:hover": {
      background: "#E0632B",
      boxShadow: `inset 0 0 15px rgba(55, 84, 170,0),
            inset 0 0 20px rgba(255, 255, 255,0),
            7px 7px 15px rgba(55, 84, 170,.15),
            -7px -7px 20px rgba(255, 255, 255,1),
            inset 0px 0px 4px rgba(255, 255, 255,.2)`,
    },
  },
  modalClass: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    borderRadius: 10,
    background: "#fff",
    border: "none",
  },

  labelName: {
    fontSize: 14,
    fontFamily: "Ubuntu",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "20px",
    color: "#E0632B",
    margin: "5px 0",
  },

  approvedStatus: {
    color: "#FFF",
    background: "#026c45",
    padding: "5px 15px",
    borderRadius: 6,
  },
  onHoldStatus: {
    color: "#FFF",
    background: "#be0000",
    padding: "5px 15px",
    borderRadius: 6,
  },
};
