import React, { useState, useEffect } from "react";
import styles from "./referrals.style";
import {
  Button,
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead, 
  TableBody,
  TableRow,
  TableCell,
  Divider,
  Snackbar,
  withStyles,
} from "@material-ui/core";
import Card from "../customStyle/cards";
import { URLS } from "../../constants/index";
import MuiAlert from "@material-ui/lab/Alert";
import { allRequestHandler } from "../../api";
import noDataImg from "../../assets/nodata.svg";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Spinner from "../customStyle/spinner";
import { icons } from "../customStyle/icons";
import SearchBar from "../customStyle/searchBar";
import moment from "moment";
const headings = ["Date", "Referred", "Service", "Points", "Amount"];
const Referrals = ({ classes, userData }) => {
  useEffect(() => {
    getRefferalsData();
  }, []);

  const [referralData, setReferralData] = useState(null);
  const [totalPoints, setTotalPoints] = useState(null);
  const [totalEarnings, setTotalEarnings] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("");

  const [handleSnackbar, setHandleSnackbar] = useState(null);
  const [displayMsg, setDisplayMsg] = useState(null);
  const [severity, setSeverity] = useState("");

  const getRefferalsData = async () => {
    const customerEmail = sessionStorage.getItem("customerEmail");
    let referalsData = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.REFERALS,
    });
    if (referalsData.status == "401") setRedirect(true);
    else {
      setLoading(false);
      setReferralData(referalsData.data);
      setTotalPoints(referalsData.points_by_referrals);
      setTotalEarnings(referalsData.total_earning);
    }
  };

  const filteredReferrals =
    searchBy == "Referred"
      ? referralData &&
        referralData.length > 0 &&
        referralData.filter((sale) => {
          return sale.tocust.toLowerCase().includes(searchTerm.toLowerCase());
        })
      : searchBy == "Points"
      ? referralData &&
        referralData.length > 0 &&
        referralData.filter((sale) => {
          return sale.points.toString().includes(searchTerm.toString());
        })
      : searchBy == "Amount"
      ? referralData &&
        referralData.length > 0 &&
        referralData.filter((sale) => {
          return sale.amount_of_billing
            .toString()
            .includes(searchTerm.toString());
        })
      : searchBy == "Service"
      ? referralData &&
        referralData.length > 0 &&
        referralData.filter((sale) => {
          return sale.service_taken
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        })
      : referralData;

  if (redirect) return <Redirect push to="/customer/login" />;

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            open={handleSnackbar}
            onClose={() => setHandleSnackbar(false)}
          >
            <MuiAlert elevation={6} variant="standard" severity={severity}>
              {" "}
              {displayMsg}{" "}
            </MuiAlert>
          </Snackbar>
          <Grid
            style={{ display: "flex", flexDirection: "column", padding: 30 }}
          >
            <Grid
              className={classes.whiteCard}
              style={{ padding: 20, position: "relative", height: 500 }}
            >
              <Grid
                container
                style={{ justifyContent: "space-between", marginBottom: 7 }}
              >
                <Grid item>
                  <Typography className={classes.typography32}>
                    Referral Statistics
                  </Typography>
                </Grid>
                <Grid item>
                  <SearchBar
                    searchBy={searchBy}
                    setSearchBy={setSearchBy}
                    menuItems={["Referred", "Service", "Points", "Amount"]}
                    keyword={searchTerm}
                    placeholder="Search by email"
                    setKeyword={setSearchTerm}
                  />
                </Grid>
              </Grid>
              <Divider />
              {referralData && referralData.length > 0 ? (
                <>
                  <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead style={{ background: "none" }}>
                        {headings.map((heading) => (
                          <TableCell
                            style={{ borderBottom: "none", fontSize: 16 }}
                            className={classes.typography32}
                          >
                            {heading}
                          </TableCell>
                        ))}
                      </TableHead>
                      <TableBody>
                        {filteredReferrals &&
                          filteredReferrals.length > 0 &&
                          filteredReferrals.map((row, index) => (
                            <TableRow
                              style={
                                index % 2
                                  ? { background: "#FFFFFF" }
                                  : { background: "#f0cdbd" }
                              }
                              className={classes.typography32}
                            >
                              <TableCell
                                style={{ borderBottom: "none" }}
                                className={classes.typography14}
                              >
                                {moment(row.date).format("MMM Do YY")}
                              </TableCell>
                              <TableCell
                                style={{ borderBottom: "none" }}
                                className={classes.typography14}
                              >
                                {row.tocust}
                              </TableCell>
                              <TableCell
                                style={{ borderBottom: "none" }}
                                className={classes.typography14}
                              >
                                {row.service_taken}
                              </TableCell>
                              <TableCell
                                style={{ borderBottom: "none" }}
                                className={classes.typography14}
                              >
                                {row.points}
                              </TableCell>
                              <TableCell
                                style={{ borderBottom: "none" }}
                                className={classes.typography14}
                              >
                                {row.amount_of_billing
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Divider />
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "50%",
                      margin: "20px auto",
                    }}
                  >
                    <Card
                      title={"Total Amount"}
                      amount={totalEarnings ? totalEarnings : 0}
                      style={{
                        width: "200px",
                        alignItems: "center",
                        background: "rgba(224, 99, 43, 0.5)",
                      }}
                    />
                    <Card
                      title={"Total Points"}
                      amount={totalPoints ? totalPoints : 0}
                      style={{
                        width: "200px",
                        alignItems: "center",
                        background: "rgba(224, 99, 43, 0.5)",
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <Grid
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: 500,
                    width: 500,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={noDataImg} />
                  <Typography
                    className={classes.typography32}
                    style={{
                      color: "#E0632B",
                      textAlign: "center",
                      margin: "1em",
                    }}
                  >
                    No Referrals found
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid
              className={classes.whiteCard}
              style={{ padding: 20, height: "auto", minHeight: "auto" }}
            >
              <Typography className={classes.typography32}>
                Your referral Code
              </Typography>
              <Typography
                className={classes.typography14}
                style={{ margin: "10px 0" }}
              >
                Share your referal code to earn money!
              </Typography>
              <Grid style={{ display: "flex", height: 40 }}>
                <Grid className={classes.linkContainer}>
                  {sessionStorage.getItem("referral_code")}
                </Grid>
                <Grid>
                  <span>
                    <Button
                      className={classes.Btn}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          sessionStorage.getItem("referral_code")
                        );
                        setHandleSnackbar(true);
                        setDisplayMsg("Copied to clipboard!");
                        setSeverity("info");
                      }}
                    >
                      Copy
                    </Button>
                  </span>
                </Grid>
              </Grid>
              <Typography
                className={classes.typography14}
                style={{ marginTop: "40px" }}
              >
                Share your code on social media platforms
              </Typography>
              <Grid
                container
                style={{
                  display: "flex",
                  margin: "30px 0px",
                  justifyContent: "space-evenly",
                  width: "60%",
                }}
              >
                {icons.map((icon) => {
                  return (
                    <Grid item style={{ margin: "0px 15px" }}>
                      <a href={icon.url} target="_blank">
                        {" "}
                        <img src={icon.icon} height={35} width={35} />
                      </a>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Referrals));
