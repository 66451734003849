export default {
    outerHeader: {
      height: 62,
      borderRadius : "8px",
      border : "1px solid #f0cdbd",
      background: "#fff",
      padding: "0 20px",
      width : "97%",
      margin : "0 auto",
      boxShadow: 
      `inset 0 0 15px rgba(55, 84, 170,0),
      inset 0 0 20px rgba(255, 255, 255,0),
      7px 7px 15px rgba(55, 84, 170,.15),
      -7px -7px 20px rgba(255, 255, 255,1),
      inset 0px 0px 4px rgba(255, 255, 255,.2)`
    },
    title: {
      fontFamily: "Ubuntu",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 20,
      lineHeight: "40px",
      color: "#08001D",
      textTransform : "capitalize"
    },
    plantLocation: {
      fontFamily: "Ubuntu",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "20px",
      color: "#536471",
      marginLeft: 8,
    },
    initialName: {
      width: 42,
      height: 42,
      background: "#F0F4FA",
      borderRadius: 72,
      fontFamily: "Ubuntu",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 12,
      lineHeight: "18px",
      color: "#26184D",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    menuOuter: {
      width: 200,
      background: "#FFFFFF",
      border: "1px solid #DDE5EC",
      boxSizing: "border-box",
      boxShadow: "0px 4px 12px rgba(78, 89, 109, 0.05)",
      borderRadius: 8,
      marginTop: 40,
    },
    menuItem: {
      height: 32,
      padding: "6px 16px",
      fontFamily: "Ubuntu",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "20px",
      color: "#536471",
    },
    menuProfile: {
      fontFamily: "Ubuntu",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "24px",
      color: "#536471",
    },
    menuEmail: {
      fontFamily: "Ubuntu",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "20px",
      color: "#8C9AA4",
    },
    menuInner: {
      height: 60,
      padding: "0 16px",
      borderBottom: "1px solid #E4EAF5",
      marginBottom: 8
    },
    profileTitle : {
      color : "#536471",
      textTransform : "uppercase",
      fontSize : 14,
      fontWeight : 600
    }
  }
