import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./reports.style";
import {
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";
import { URLS } from "../../constants/index";
import { allRequestHandler } from "../../api";
import SearchBar from "../customStyle/searchBar";
import noDataImg from "../../assets/nodata.svg";
import { Redirect } from "react-router-dom";
import Spinner from "../customStyle/spinner";
import moment from 'moment'


const headings = [
  "Date",
  "Vendor Email ID",
  "Place",
  "Service Taken",
  "Points",
  "Amount",
];

const Reports = ({ classes }) => {
  useEffect(() => {
    getReportData();
  }, []);

  const [monthlyReport, setMonthlyReport] = useState([]);
  const [redirect, setRedirect] = useState(null);
  const [reportsData, setReportsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchReport, setSearchReport] = useState("")
  const [searchBy, setSearchBy] = useState("");

  const handleDetails = (month) => {
    if (month == "August") setMonthlyReport(reportsData.splice(-4));
    else if (month == "September") setMonthlyReport(reportsData.splice(0, 4));
  };

  const getReportData = async () => {
    const userid = sessionStorage.getItem("userId");
    const customerEmail = sessionStorage.getItem("customerEmail");
    let reportsData = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.GETSALES + `?tocust=${customerEmail}&status=true`,
    });
    if (reportsData) {
      setReportsData(reportsData);
      setLoading(false);
    }
    setReportsData(reportsData);

    if (reportsData.status == "401") setRedirect(true);
  };

  
  const filteredReportHistory =
   searchBy == "Vendor Email ID" ?
  reportsData &&
  reportsData.length > 0 &&
  reportsData.filter((report) => {
    return report.createdBy
      .toLowerCase()
      .includes(searchReport.toLowerCase());
  }) 
  :
  searchBy ==  "Place" ?
  reportsData &&
  reportsData.length > 0 &&
  reportsData.filter((report) => {
    return report.place
      .toLowerCase()
      .includes(searchReport.toLowerCase());
  }) 
  :
  searchBy ==  "Service Taken" ?
  reportsData &&
  reportsData.length > 0 &&
  reportsData.filter((report) => {
    return report.service_taken
      .toLowerCase()
      .includes(searchReport.toLowerCase());
  }) 
  :
  searchBy == "Points" ?
  reportsData &&
  reportsData.length > 0 &&
  reportsData.filter((report) => {
    return report.points.toFixed(1)
      .toLowerCase()
      .includes(searchReport.toLowerCase());
  }) 
  :
  searchBy ==  "Amount" ?
  reportsData &&
  reportsData.length > 0 &&
  reportsData.filter((report) => {
    return report.amount_of_billing
      .toString()
      .includes(searchReport.toString());
  }) 
  : reportsData


  if (redirect) return <Redirect push to="/customer/login" />;
 

  return (
 
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {reportsData && reportsData.length > 0 ? (
            <Grid className={classes.outerContainer}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid item>
                  <Typography className={classes.typography32}>
                    Report Statistics
                  </Typography>
                </Grid>
                <Grid item>
                  <SearchBar 
                  searchBy = {searchBy}
                  setSearchBy = {setSearchBy}
                  menuItems = {headings.slice(1)}
                    keyword={searchReport}
                    placeholder="Search by vendor email"
                    setKeyword={setSearchReport}
                  />
                </Grid>
              </Grid>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {headings.map((heading) => (
                        <TableCell
                          style={{ borderBottom: "none" }}
                          className={classes.tableHeaderClass}
                        >
                          {heading}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredReportHistory &&
                     filteredReportHistory.length > 0 &&
                      filteredReportHistory.map((row, index) => (
                        <TableRow
                          style={
                            index % 2
                              ? { background: "#fff" }
                              : { background: "rgba(224, 99, 43, 0.25)" }
                          }
                          className={classes.typography32}
                        >
                          <TableCell className={classes.tableCellClass}>
                            {moment(row.date).format("MMM Do YY")}
                           
                          </TableCell>
                          <TableCell className={classes.tableCellClass}> 
                            {row.createdBy}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.place}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.service_taken}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.points.toFixed(1)}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.amount_of_billing
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : (
            <Grid
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                height: 500,
                width: 500,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={noDataImg} />
              <Typography
                className={classes.typography32}
                style={{ color: "#E0632B", textAlign: "center", margin: "1em" }}
              >
                No Reports Found
              </Typography>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default withStyles(styles)(Reports);
