export default {
    outerContainer : {
        height : "auto",
        // width : "50%",
        padding : 20,
        background : "#FFFFFF",
        margin : "20px",
        borderRadius : 12,
        fontFamily : "Ubuntu",
        boxShadow: 
        `inset 0 0 15px rgba(55, 84, 170,0),
        inset 0 0 20px rgba(255, 255, 255,0),
        7px 7px 15px rgba(55, 84, 170,.15),
        -7px -7px 20px rgba(255, 255, 255,1),
        inset 0px 0px 4px rgba(255, 255, 255,.2)`
    },
    modalClass: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "30%",
        borderRadius: 10,
        background: "#fff",
        border: "none",
      },
    
    typography32 : {
        fontSize : 20, 
        color : "#26184D",
        fontWeight : 600,
        fontFamily : "Ubuntu"
     },

     emailScroll : {
      height : 100,
      width: "80%",
      borderRadius: 8,
      background: "#F0EBFE",
      padding : "5px 15px",
      marginTop : 10,
      overflowY: "scroll"
     },
     labelName: {
        fontSize: 14,
        fontFamily:"Ubuntu",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "20px",
        color: "#E0632B",
        margin : "5px 0"
    }, 
     typography14 : {
        fontSize : 13,
        color : "#000",
        letterSpacing : 0.6,
        fontStyle: "normal",
        fontWeight : 400,
        fontFamily : "Ubuntu",
        fontStyle : "normal"
     },
     Btn: {
        textTransform: "capitalize",
        fontFamily: "Ubuntu",
        fontSize: 14,
        color: "#FFF",
        margin: "0px 5px",
        fontWeight: 500,
        background: "#E0632B",
        padding: "2px 10px",
        borderRadius: 6,
        border: "3px solid #E0632B",
        cursor: "pointer",
        "&:hover": {
          background: "#E0632B",
          boxShadow: `inset 0 0 15px rgba(55, 84, 170,0),
              inset 0 0 20px rgba(255, 255, 255,0),
              7px 7px 15px rgba(55, 84, 170,.15),
              -7px -7px 20px rgba(255, 255, 255,1),
              inset 0px 0px 4px rgba(255, 255, 255,.2)`,
        },
      },

      //Table styles
    tableContainer : {
        height : "500px",
        margin : "20px 0",
        borderTopLeftRadius : 10,
        borderTopRightRadius : 10, 
    },

    tableHeaderClass : {
        fontSize : 16,
        color : "#26184D",
        fontWeight : 600,
        fontFamily : "Ubuntu",
        border : "none",
        background : "#fff",
        // textAlign : "center",
        padding : 20
        // borderRadius: 10
    },

    tableCellClass : {
        fontSize : 14,
        color : "#5c5c5c",
        fontWeight : 400,
        fontFamily : "Ubuntu",
        fontStyle : "normal",
        letterSpacing : 0.4,       
        padding : 20,
        borderBottom : "none"
    },

    tableRowClass : {
        "&:hover" : {
            background : "rgba(224, 99, 43, 0.15)",
        }
    },
    approvedStatus : {
        color : "#FFF",
        background :  "#026c45",
        padding : "5px 15px",
        borderRadius : 6,
    },
    onHoldStatus : {
        color : "#FFF",
        background :  "#be0000",
        padding : "5px 15px",
        borderRadius : 6,
    },

}
