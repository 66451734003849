import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import styles from "./sales.style";
import {
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Divider,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
// import Snackbar from  "@material-ui/lab/Snackbar";
import CustomTextField from "../customStyle/textfield";
import { URLS } from "../../constants/index";
import { allRequestHandler } from "../../api";
import noDataImg from "../../assets/search.png";
import noDataImg2 from "../../assets/nodata.svg";
import { Redirect } from "react-router-dom";
import Spinner from "../customStyle/spinner";
import SearchBar from "../customStyle/searchBar";
import SalesInvoice from "./invoice";
import moment from "moment";
import NewWindow from "react-new-window";

const Sales = ({ classes }) => {
  const [userId, setUserId] = useState(null);
  const [place, setPlace] = useState(null);
  const [billAmt, setBillAmt] = useState(null);
  const [advRecieved, setAdvRecieved] = useState(null);
  const [payRefNo, setPayRefNo] = useState(null);
  const [serviceTaken, setServiceTaken] = useState(null);
  const [orderDesc, setOrderDesc] = useState(null);
  const [shippingAmt, setShippingAmt] = useState(null);
  const [salesData, setSalesData] = useState(null);
  const [approvedSales, setApprovedSales] = useState(null);
  const [requestSent, setRequestSent] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(true);
  const [handleSnackbar, setHandleSnackbar] = useState(null);
  const [displayMsg, setDisplayMsg] = useState(null);
  const [salesInvoiceData, setSalesInvoiceData] = useState(null);
  const [showInvoice, setShowInvoice] = useState(false);
  const [severity, setSeverity] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("");

  useEffect(() => {
    getSales();
    getRandomString(6);
  }, []);

  const getRandomString = (length) => {
    var randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    setPayRefNo("FOB-01-" + result);
    return "FOB-01-" + result;
  };

  const salesTextField = [
    {
      name: "Customer Email",
      placeholder: "Customer email",
      value: userId,
      type: "email",
      disabled: false,
    },
    {
      name: "Place",
      placeholder: "Location",
      value: place,
      type: "text",
      disabled: false,
    },
    {
      name: "Amount of Billing",
      placeholder: "Enter the amount to be billed",
      value: billAmt,
      type: "number",
      disabled: false,
    },
    {
      name: "Advance Received",
      placeholder: "Enter the advance amount",
      value: advRecieved,
      type: "number",
      disabled: false,
    },
    {
      name: "Payment Reference Number",
      placeholder: "Enter the payment reference number",
      value: payRefNo,
      type: "number",
      disabled: true,
    },
    {
      name: "Service Taken",
      placeholder: "Enter the service taken",
      value: serviceTaken,
      type: "text",
      disabled: false,
    },
    {
      name: "Order Description",
      placeholder: "Give description of the order",
      value: orderDesc,
      type: "text",
      disabled: false,
    },
    {
      name: "Shipping Amount",
      placeholder: "Enter the shipping amount",
      value: shippingAmt,
      type: "text",
      disabled: false,
    },
  ];

  const headings = [
    "Date",
    "Customer Email",
    "Place",
    "Amount",
    "Service Taken",
    "Status",
  ];

  const handleChange = (e, name) => {
    name == "Customer Email"
      ? setUserId(e.target.value)
      : name == "Place"
      ? setPlace(e.target.value)
      : name == "Amount of Billing"
      ? setBillAmt(e.target.value)
      : name == "Advance Received"
      ? setAdvRecieved(e.target.value)
      : name == "Payment Reference Number"
      ? setPayRefNo(e.target.value)
      : name == "Service Taken"
      ? setServiceTaken(e.target.value)
      : name == "Order Description"
      ? setOrderDesc(e.target.value)
      : name == "Shipping Amount"
      ? setShippingAmt(e.target.value)
      : console.log("no val");
  };

  const clearSalesForm = () => {
    setUserId("");
    setPlace("");
    setBillAmt("");
    setAdvRecieved("");
    setPayRefNo("");
    setServiceTaken("");
    setOrderDesc("");
    setShippingAmt("");
  };

  const sendForApproval = async (e) => {
    e.preventDefault();
    const formData = {
      tocust: userId,
      place: place,
      amount_of_billing: billAmt,
      payment_reference_number: payRefNo,
      service_taken: serviceTaken,
      order_description: orderDesc,
      advance_received: advRecieved,
      createdBy: sessionStorage.getItem("vendorEmail"),
      shipping_amount: shippingAmt,
    };

    let createSale = await allRequestHandler({
      requestType: "POST",
      requestUrl: URLS.SALESFORM,
      requestData: formData,
    });

    console.log(createSale);
    if (createSale.response) {
      setDisplayMsg("Request Sent!");
      setHandleSnackbar(true);
      setSeverity("success");
      setRedirect(false);
      setRequestSent(true);
      clearSalesForm();
      getSales();
      window.location.reload();
    } else {
      let validationMsg = createSale.data.error
        ? createSale.data.error
        : createSale.data.response;
      setDisplayMsg(validationMsg);
      setHandleSnackbar(true);
      setSeverity("error");
      setRedirect(false);
    }
  };

  const getSales = async () => {
    const vendorEmail = sessionStorage.getItem("vendorEmail");
    let salesData = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.GETSALES + `?createdBy=${vendorEmail}`,
      //  "?userid=" + sessionStorage.getItem("userId"),
    });
    if (salesData.status == "401") setRedirect(true);
    else {
      setLoading(false);
      setSalesData(salesData);
      let approvedSales = salesData?.filter((sale) => sale.status == true);
      setApprovedSales(approvedSales);
    }
  };

  const generateInvoice = async (id) => {
    let salesData = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.GENERATEINVOICE + `${id}`,
    });

    console.log(salesData);

    setSalesInvoiceData(salesData.context);
    setShowInvoice(true);
  };

  const filteredSales =
    searchBy == "Customer Email"
      ? salesData &&
        salesData.length > 0 &&
        salesData.filter((sale) => {
          return sale.tocust.toLowerCase().includes(searchTerm.toLowerCase());
        })
      : searchBy == "Place"
      ? salesData &&
        salesData.length > 0 &&
        salesData.filter((sale) => {
          return sale.place.toLowerCase().includes(searchTerm.toLowerCase());
        })
      : searchBy == "Amount"
      ? salesData &&
        salesData.length > 0 &&
        salesData.filter((sale) => {
          return sale.amount_of_billing
            .toString()
            .includes(searchTerm.toString());
        })
      : searchBy == "Service Taken"
      ? salesData &&
        salesData.length > 0 &&
        salesData.filter((sale) => {
          return sale.service_taken
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        })
      : salesData;

  if (redirect) return <Redirect push to="/vendor/login" />;

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            open={handleSnackbar}
            onClose={() => setHandleSnackbar(false)}
          >
            <MuiAlert elevation={6} variant="standard" severity={severity}>
              {" "}
              {displayMsg}{" "}
            </MuiAlert>
          </Snackbar>
          <Grid className={classes.outerContainer}>
            <Typography
              className={classes.typography32}
              style={{ marginBottom: 10 }}
            >
              Create a New Sale
            </Typography>
            <Divider />
            <form style={{ padding: 20 }} onSubmit={(e) => sendForApproval(e)}>
              <Grid
                container
                style={{ justifyContent: "space-between", width: "95%" }}
              >
                <Grid item style={{ width: "47%" }}>
                  {salesTextField.slice(0, 4).map((field) => {
                    return (
                      <div style={{ marginBottom: 20 }}>
                        <Typography className={classes.labelName}>
                          {field.name}
                        </Typography>
                        <CustomTextField
                          // fullWidth = {false}
                          id="email"
                          type={field.type}
                          name="email"
                          autoComplete="off"
                          autoFocus
                          BoxHeight="68px"
                          value={field.value}
                          placeholder={field.placeholder}
                          disabled={field.disabled}
                          handleChange={(e) => handleChange(e, field.name)}
                        />
                      </div>
                    );
                  })}
                </Grid>
                <Grid item style={{ width: "47%" }}>
                  <div style={{ marginBottom: 20 }}>
                    <Typography className={classes.labelName}>
                      Payment Reference Number
                    </Typography>
                    <div
                      style={{
                        padding: 12,
                        background: "#F0F4FA",
                        borderRadius: 5,
                        fontFamily: "Ubuntu",
                        fontSize: 14,
                      }}
                    >
                      {payRefNo}
                    </div>
                  </div>
                  {salesTextField.slice(-3).map((field) => {
                    return (
                      <div style={{ marginBottom: 20 }}>
                        <Typography className={classes.labelName}>
                          {field.name}
                        </Typography>
                        <CustomTextField
                          // fullWidth = {false}
                          id="email"
                          name="email"
                          autoComplete="off"
                          autoFocus
                          BoxHeight={"38px"}
                          value={field.value}
                          placeholder={field.placeholder}
                          handleChange={(e) => handleChange(e, field.name)}
                        />
                      </div>
                    );
                  })}
                </Grid>
              </Grid>

              <div
                style={{
                  display: "flex",
                  padding: 30,
                  width: "30%",
                  margin: "0 auto",
                  justifyContent: "center",
                }}
              >
                <Button className={classes.Btn} type="submit">
                  Send For Approval
                </Button>
              </div>
            </form>
          </Grid>

          <Grid
            className={classes.outerContainer}
            style={{ position: "relative" }}
          >
            <Grid
              container
              style={{ justifyContent: "space-between", marginBottom: 7 }}
            >
              <Grid item>
                <Typography className={classes.typography32}>
                  Previous Sales
                </Typography>
              </Grid>
              <Grid item>
                <SearchBar
                  searchBy={searchBy}
                  setSearchBy={setSearchBy}
                  menuItems={headings.slice(1, -1)}
                  keyword={searchTerm}
                  placeholder="Search by email"
                  setKeyword={setSearchTerm}
                />
              </Grid>
            </Grid>
            <Divider />
            {salesData && salesData.length > 0 ? (
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {headings.map((heading) => (
                        <TableCell
                          className={classes.tableHeaderClass}
                          style={{ borderBottom: "none" }}
                        >
                          {heading}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredSales &&
                      filteredSales.length > 0 &&
                      filteredSales.map((row, index) => (
                        <TableRow
                          className={classes.tableRowClass}
                          // style = {index % 2 == 0 ? {  background : "rgba(224, 99, 43, 0.15)" } : { background : "#fff" }}
                        >
                          <TableCell className={classes.tableCellClass}>
                            {moment(row.date).format("MMM Do YY")}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.tocust}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.place}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.amount_of_billing
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.service_taken}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.status == true ? (
                              <span className={classes.approvedStatus}>
                                Approved
                              </span>
                            ) : (
                              <span className={classes.onHoldStatus}>
                                On hold
                              </span>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Grid
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  height: 500,
                  width: 500,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={noDataImg} height={100} width={100} style = {{ marginTop : 50}}  />
                <Typography
                  className={classes.typography32}
                  style={{ textAlign: "center", margin: "1em" }}
                >
                  No Sales Approved yet
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid
            className={classes.outerContainer}
            style={{ height: "auto", position: "relative" }}
          >
            <Typography
              className={classes.typography32}
              style={{ marginBottom: 10 }}
            >
              Approved Sales Invoice
            </Typography>
            <Divider />
            <Grid className={classes.cardList}>
              {approvedSales && approvedSales.length > 0 ? (
                approvedSales?.map(
                  (row) =>
                    row.status == true && (
                      <Grid style={{ marginBottom: 20 }}>
                        <Grid item>
                          <a
                            href={`https://api.fobiz.net/v1/api/vendor/invoice/${row.id}`}
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <Grid className={classes.cardContainer}>
                              <svg
                                width="37"
                                height="45"
                                viewBox="0 0 37 45"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M22.9163 0.416748H5.24967C4.0783 0.416748 2.9549 0.882074 2.12662 1.71036C1.29833 2.53865 0.833008 3.66204 0.833008 4.83342V40.1668C0.833008 41.3381 1.29833 42.4615 2.12662 43.2898C2.9549 44.1181 4.0783 44.5834 5.24967 44.5834H31.7497C32.921 44.5834 34.0444 44.1181 34.8727 43.2898C35.701 42.4615 36.1663 41.3381 36.1663 40.1668V13.6667L22.9163 0.416748ZM12.9744 31.753C12.292 32.3934 11.285 32.6805 10.1124 32.6805C9.88505 32.683 9.65779 32.6697 9.43226 32.6407V35.7898H7.45801V27.0978C8.34897 26.9649 9.24923 26.9044 10.15 26.9167C11.38 26.9167 12.2545 27.1508 12.8441 27.6212C13.405 28.0673 13.7849 28.7983 13.7849 29.6595C13.7827 30.5252 13.4956 31.2561 12.9744 31.753ZM21.3815 34.7453C20.454 35.516 19.0429 35.8826 17.3182 35.8826C16.2847 35.8826 15.5538 35.8163 15.0569 35.7501V27.1C15.9482 26.97 16.8481 26.9087 17.7488 26.9167C19.4205 26.9167 20.507 27.2171 21.355 27.8575C22.2715 28.5377 22.8457 29.622 22.8457 31.1788C22.8457 32.8638 22.2295 34.0276 21.3815 34.7453ZM29.5413 28.6172H26.1582V30.629H29.3205V32.2499H26.1582V35.792H24.1574V26.983H29.5413V28.6172ZM22.9163 15.8751H20.708V4.83342L31.7497 15.8751H22.9163Z"
                                  fill="#26184D"
                                />
                              </svg>
                              <Typography className={classes.amountTypography}>
                                <span style={{ fontSize: 23 }}>
                                  {" "}
                                  Creation Date
                                </span>
                              </Typography>
                              <Typography className={classes.titleTypography}>
                                {moment(row.date).format("MMM Do YY")}
                              </Typography>
                            </Grid>
                          </a>
                        </Grid>
                      </Grid>
                    )
                )
              ) : (
                <Grid
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: 500,
                    width: 500,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={noDataImg2} height={100} width={100} style = {{ marginTop : 50}} />
                  <Typography
                    className={classes.typography32}
                    style={{ textAlign: "center", margin: "1em" }}
                  >
                   No invoices  found
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(Sales);
