import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  withStyles,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CustomTextField from "../customStyle/textfield";
import styles from "./forgotPassword.style";
import { URLS } from "../../constants/index";
import { allRequestHandler } from "../../api";
import { useHistory, Link, Redirect } from "react-router-dom";
const CustomerLogin = ({ classes, setUserData }) => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [token, setToken] = useState(null);
  const [status, setStatus] = useState(false);
  const [handleSnackbar, setHandleSnackbar] = useState(null);
  const [displayMsg, setDisplayMsg] = useState(null);
  const [url, setUrl] = useState(null);
  const [severity, setSeverity] = useState("");
 const [loginRedirect, setLoginRedirect] = useState(false)
  useEffect(() => {
    const role = sessionStorage.getItem("role");
    if (role == "customer")  setUrl("/customer/login");
    if (role == "vendor") setUrl("/vendor/login");
  }, []);

  const submitEmail = async (e) => {
    e.preventDefault();
    const emailData = {
      email: email,
    };
    const gettingToken = await allRequestHandler({
      requestType: "POST",
      requestUrl: URLS.FORGOTPASSWORDTOKEN,
      requestData: emailData,
    });
    if (gettingToken.status == "OK") setStatus(true);

    setToken(gettingToken.token);
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    const data = {
      token: token,
      password: password,
    };

    const update = await allRequestHandler({
      requestType: "POST",
      requestUrl: URLS.FORGOTPASSWORD,
      requestData: data,
    });


    if (password !== "" && confirmPassword !== "") {
      if (password !== confirmPassword) {
        setDisplayMsg("Password and confirm password dont match");
        setHandleSnackbar(true);
        setSeverity("error");
      } else {
        if (update.status == "OK") {        
          setHandleSnackbar(true);
          setDisplayMsg("Password updated successfully!");
          setSeverity("success");
          setLoginRedirect(true)
                  }
          else {
            setHandleSnackbar(true);
            setDisplayMsg(update.data.password.join());
            setSeverity("error");
          }
      }
    } else {
      setDisplayMsg("Password fields cannot be empty");
      setHandleSnackbar(true);
      setSeverity("error");
    }
  };

  return (
    <>
      <Grid className={classes.outerLogin}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          open={handleSnackbar}
          onClose={() => setHandleSnackbar(false)}
        >
          <MuiAlert
            elevation={6}
            variant="standard"
            severity={severity}
          >
            {" "}
            {displayMsg}{" "}
          </MuiAlert>
        </Snackbar>

        <Grid className={classes.loginBox}>
          <Typography
            style={{
              fontSize: 35,
              fontFamily: "Ubuntu",
              fontWeight: 600,
              margin: 10,
            }}
          >
            {status ? "Enter your new password" : "Forgot Password ?"}
          </Typography>

          <Grid className={classes.login}>
            {status ? (
              <form onSubmit={(e) => updatePassword(e)}>
                <div>
                  <Typography
                    className={classes.labelName}
                    style={{ marginTop: 10 }}
                  >
                    Enter your new password
                  </Typography>

                  <CustomTextField
                    id="email"
                    name="email"
                    type="password"
                    autoComplete="off"
                    autoFocus
                    BoxHeight={"38px"}
                    handleChange={(e) => setPassword(e.target.value)}
                  />

                  <Typography
                    className={classes.labelName}
                    style={{ marginTop: 10 }}
                  >
                    Confirm Password
                  </Typography>

                  <CustomTextField
                    id="email"
                    name="email"
                    type="password"
                    autoComplete="off"
                    autoFocus
                    BoxHeight={"38px"}
                    handleChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.loginBtn}
                >
                  Update Password
                </Button>
              </form>
            ) : (
              <form onSubmit={(e) => submitEmail(e)}>
                <div>
                  <Typography
                    className={classes.labelName}
                    style={{ marginBottom: 20 }}
                  >
                    Enter your email address
                  </Typography>

                  <CustomTextField
                    id="email"
                    name="email"
                    type="text"
                    placeholder="username@email.com"
                    autoComplete="off"
                    autoFocus
                    BoxHeight={"38px"}
                    handleChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.loginBtn}
                >
                  Submit
                </Button>
              </form>
            )}
          </Grid>
          <Link to={url}>
            <Typography
              style={{
                color: "#346BD4",
                fontSize: 14,
                fontFamily: "Ubuntu",
                textDecoration: "none",
                margin: "20px auto",
              }}
            >
             {loginRedirect ? "Login with new password" :  "Back To Login" }
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(CustomerLogin);
