import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./withdrawalRequest.style";
import {
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Divider,
} from "@material-ui/core";
import { URLS } from "../../../constants/index";
import { allRequestHandler } from "../../../api";
import MenuOptions from "../menuOptions";
import noDataImg from "../../../assets/search.png";
import { Redirect } from "react-router-dom";
import Spinner from "../../customStyle/spinner";
import moment from "moment";
import SearchBar from "../../customStyle/searchBar";
import Card from "../../customStyle/cards"

const headings = [
  "Date",
  "Email ID",
  "Amount",
  "Bank",
  "Account No",
  "IFSC Code",
  "",
];

const WithdrawalApproval = ({ classes }) => {
  const [withdrawalForApproval, setWithdrawalForApproval] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [approvedRequests, setApprovedRequests] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchWithdrawalTerm, setSearchWithdrwalTerm] = useState("");
  const [searchApprovedRequest, setSearchApprovedRequest] = useState("");

  const [searchBy, setSearchBy] = useState("");
  const [approvedSearchBy, setApprovedSearchBy] = useState("");

  useEffect(() => {
    getAllWithdrawals();
  }, []);

  const getAllWithdrawals = async () => {
    let approvedWithdrawals = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.GETWITHDRAWALS + `?status=True`,
    });

    let onHoldWithdrawals = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.GETWITHDRAWALS + `?status=False`,
    });

    if (
      approvedWithdrawals.status == "401" ||
      onHoldWithdrawals.status == "401"
    )
      setRedirect(true);
    else {
      setLoading(false);
      setWithdrawalForApproval(onHoldWithdrawals.response.withdrawrequest);
      setApprovedRequests(approvedWithdrawals.response.withdrawrequest);
      setUserDetails(approvedWithdrawals.response.user_details);
    }
  };

  const getTotal = (array) => {
    return array.map(am => am.amount_requested).reduce((a, b) => a + b, 0)
  }
  const getBankDetails = (email) => {
    let value =
      userDetails && userDetails.filter((user) => user.email == email);
    if (value && value[0]) return value[0];
  };

  const filteredWithdrawalRequests =
    searchBy == "Email ID"
      ? withdrawalForApproval &&
        withdrawalForApproval.length > 0 &&
        withdrawalForApproval.filter((withdrawal) => {
          console.log(searchBy);
          return withdrawal.customer_email
            ?.toLowerCase()
            .includes(searchWithdrawalTerm.toLowerCase());
        })
      : searchBy == "Amount"
      ? withdrawalForApproval &&
        withdrawalForApproval.length > 0 &&
        withdrawalForApproval.filter((withdrawal) => {
          return withdrawal.amount_requested
            ?.toString()
            .includes(searchWithdrawalTerm.toString());
        })
      : searchBy == "Bank"
      ? withdrawalForApproval &&
        withdrawalForApproval.length > 0 &&
        withdrawalForApproval.filter((withdrawal) => {
          return getBankDetails(withdrawal.customer_email)
          ?.bankname?.toLowerCase()
          .includes(searchWithdrawalTerm.toLowerCase());
        })
      : searchBy == "Account No"
      ? withdrawalForApproval &&
        withdrawalForApproval.length > 0 &&
        withdrawalForApproval.filter((withdrawal) => {
          return getBankDetails(withdrawal.customer_email)
            ?.bank_account?.toLowerCase()
            .includes(searchWithdrawalTerm.toLowerCase());
        })
      : searchBy == "IFSC Code"
      ? withdrawalForApproval &&
        withdrawalForApproval.length > 0 &&
        withdrawalForApproval.filter((withdrawal) => {
          return getBankDetails(withdrawal.customer_email)
          ?.ifsccode?.toLowerCase()
          .includes(searchWithdrawalTerm.toLowerCase());
        })
      : withdrawalForApproval;

  const filteredApprovedRequests =
    approvedSearchBy == "Email ID"
      ? approvedRequests &&
        approvedRequests.length > 0 &&
        approvedRequests.filter((withdrawal) => {
          return withdrawal.customer_email
            ?.toLowerCase()
            .includes(searchApprovedRequest.toLowerCase());
        })
      : approvedSearchBy == "Amount"
      ? approvedRequests &&
        approvedRequests.length > 0 &&
        approvedRequests.filter((withdrawal) => {
          return withdrawal.amount_requested
            ?.toString()
            .includes(searchApprovedRequest.toString());
        })
      : approvedSearchBy == "Bank"
      ? approvedRequests &&
        approvedRequests.length > 0 &&
        approvedRequests.filter((withdrawal) => {
          return getBankDetails(withdrawal.customer_email)
            ?.bankname?.toLowerCase()
            .includes(searchApprovedRequest.toLowerCase());
        })
      : approvedSearchBy == "Account No"
      ? approvedRequests &&
        approvedRequests.length > 0 &&
        approvedRequests.filter((withdrawal) => {
          return getBankDetails(withdrawal.customer_email)
            ?.bank_account?.toLowerCase()
            .includes(searchApprovedRequest.toLowerCase());
        })
      : approvedSearchBy == "IFSC Code"
      ? approvedRequests &&
        approvedRequests.length > 0 &&
        approvedRequests.filter((withdrawal) => {
          return getBankDetails(withdrawal.customer_email)
            ?.ifsccode?.toLowerCase()
            .includes(searchApprovedRequest.toLowerCase());
        })
      : approvedRequests;

  if (redirect) return <Redirect push to="/admin/login" />;

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Grid
            className={classes.outerContainer}
            style={{ position: "relative" }}
          >
            <Grid
              container
              style={{ justifyContent: "space-between", marginBottom: 7 }}
            >
              <Grid item>
                <Typography className={classes.typography32}>
                  Withdrawal Requests<span style={{  color : "#7e7e7e", fontWeight: 400}}> ({withdrawalForApproval?.length})</span>
                </Typography>
              </Grid>

              <Grid item>
                <SearchBar
                  searchBy={searchBy}
                  setSearchBy={setSearchBy}
                  menuItems={headings.slice(1, -1)}
                  keyword={searchWithdrawalTerm}
                  placeholder="Search by email"
                  setKeyword={setSearchWithdrwalTerm}
                />
              </Grid>
            </Grid>
            <Divider />
            {withdrawalForApproval && withdrawalForApproval.length > 0 ? (
              <>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {headings.map((heading) => (
                        <TableCell
                          style={{ borderBottom: "none" }}
                          className={classes.tableHeaderClass}
                        >
                          {heading}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredWithdrawalRequests &&
                      filteredWithdrawalRequests.length > 0 &&
                      filteredWithdrawalRequests.map((row, index) => (
                        <TableRow
                          style={
                            index % 2
                              ? { background: "#fff" }
                              : { background: "rgba(224, 99, 43, 0.25)" }
                          }
                          className={classes.typography32}
                        >
                          <TableCell className={classes.tableCellClass}>
                            {row.requested_on
                              ? moment(row.requested_on).format("MMM Do YY")
                              : "-"}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.customer_email}
                          </TableCell>

                          <TableCell className={classes.tableCellClass}>
                            {row.amount_requested
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {getBankDetails(row.customer_email)?.bankname}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {getBankDetails(row.customer_email)?.bank_account}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {getBankDetails(row?.customer_email)?.ifsccode}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            <MenuOptions
                              menuOptions={["Approve and mark as paid"]}
                              approveWithdrawId={row.id}
                              getAllWithdrawals={getAllWithdrawals}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
                 <Divider />
                 <Grid
                   style={{
                     display: "flex",
                     justifyContent: "space-between",
                     width: "30%",
                     margin: "20px auto",
                   }}
                 >
                   <Card
                     title={"Total Amount"}
                     amount={getTotal(filteredWithdrawalRequests)}
                     style={{
                       width: "200px",
                       alignItems: "center",
                       background: "rgba(224, 99, 43, 0.5)",
                     }}
                   />
                   </Grid>
                   </>
            ) : (
              <Grid
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  height: 500,
                  width: 500,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={noDataImg} height={200} width={200} />
                <Typography
                  className={classes.typography32}
                  style={{ textAlign: "center", margin: "1em" }}
                >
                  No Pending Requests
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid
            className={classes.outerContainer}
            style={{ position: "relative" }}
          >
            <Grid
              container
              style={{ justifyContent: "space-between", marginBottom: 7 }}
            >
              <Grid item>
                <Typography className={classes.typography32}>
                  Paid Requests<span style={{  color : "#7e7e7e", fontWeight: 400}}> ({approvedRequests?.length})</span>
                </Typography>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <SearchBar
                      searchBy={approvedSearchBy}
                      setSearchBy={setApprovedSearchBy}
                      menuItems={headings.slice(1, -1)}
                      keyword={searchApprovedRequest}
                      placeholder="Search by email"
                      setKeyword={setSearchApprovedRequest}
                    />
                  </Grid>
                  <Grid item>
                    <a
                      href="https://api.fobiz.net/v1/api/excel/withdrawals"
                      style={{ textDecoration: "none" }}
                    >
                      {" "}
                      <Button
                        className={classes.Btn}
                        style={{ padding: "2px 10px", margin: "0px 5px" }}
                      >
                        Export to excel
                      </Button>{" "}
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            {approvedRequests && approvedRequests.length > 0 ? (
              <>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {headings.map((heading) => (
                        <TableCell
                          style={{ borderBottom: "none" }}
                          className={classes.tableHeaderClass}
                        >
                          {heading}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredApprovedRequests &&
                      filteredApprovedRequests.length > 0 &&
                      filteredApprovedRequests.map((row, index) => (
                        <TableRow
                          style={
                            index % 2
                              ? { background: "#fff" }
                              : { background: "rgba(224, 99, 43, 0.25)" }
                          }
                          className={classes.typography32}
                        >
                          <TableCell className={classes.tableCellClass}>
                            {row.requested_on
                              ? moment(row.requested_on).format("MMM Do YY")
                              : "-"}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.customer_email}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.amount_requested
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {getBankDetails(row.customer_email)?.bankname}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {getBankDetails(row.customer_email)?.bank_account}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {getBankDetails(row?.customer_email)?.ifsccode}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            <span className={classes.approvedStatus}>
                              Approved
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider />
                 <Grid
                   style={{
                     display: "flex",
                     justifyContent: "space-between",
                     width: "30%",
                     margin: "20px auto",
                   }}
                 >
                   <Card
                     title={"Total Amount"}
                     amount={getTotal(filteredApprovedRequests)}
                     style={{
                       width: "200px",
                       alignItems: "center",
                       background: "rgba(224, 99, 43, 0.5)",
                     }}
                   />
                   </Grid>
              </>
            ) : (
              <Grid
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  height: 500,
                  width: 500,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={noDataImg} height={200} width={200} />
                <Typography
                  className={classes.typography32}
                  style={{ textAlign: "center", margin: "1em" }}
                >
                  No Withdrawal Requests Paid yet
                </Typography>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(WithdrawalApproval);
