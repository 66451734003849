import {
  Button,
  Grid,
  Typography,
  Menu,
  MenuItem,
  Modal,
  Box,
  Divider,
  Snackbar,
  withStyles,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import MenuDot from "../../assets/menuDot.svg";
import styles from "./salesApproval/salesApproval.style";
import CustomTextField from "../customStyle/textfield";
import { allRequestHandler } from "../../api";
import { URLS } from "../../constants/index";
import MuiAlert from "@material-ui/lab/Alert";
const MenuOptions = ({
  classes,
  userInfo,
  menuOptions,
  modalTitle,
  approveSaleId,
  getSalesForApproval,
  approveWithdrawId,
  getAllWithdrawals,
  getUsers,
  getBlockedVendors
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [bonusPoint, setBonusPoint] = useState(null);
  const [pcrPoint, setPcrPoint] = useState(null);
  const [depositValue, setDepositValue] = useState(null);
  const [handleSnackbar, setHandleSnackbar] = useState(null);
  const [displayMsg, setDisplayMsg] = useState(null);
  const [severity, setSeverity] = useState("");

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showModal = (option) => {
    setModalType(option);
    setOpenModal(true);
    setAnchorEl(false);
  };

  const addPoints = async () => {
    const pointsData = {
      points: bonusPoint,
      emailid: [userInfo.email],
    };
    const addingPoints = await allRequestHandler({
      requestType: "POST",
      requestUrl: URLS.ADDBONUSPOINT,
      requestData: pointsData,
    });
    console.log(addingPoints);

    if (bonusPoint !== "") {
      if (addingPoints) {
        setDisplayMsg("Bonus Points added!");
        setHandleSnackbar(true);
        setSeverity("success");
        setOpenModal(false);
      } else {
        setDisplayMsg("Something went wrong");
        setHandleSnackbar(true);
        setSeverity("error");
      }
    } else {
      setDisplayMsg("Please enter some point value");
      setHandleSnackbar(true);
      setSeverity("error");
    }
  };

  const setPcr = async () => {
    const pointsData = {
      points: pcrPoint,
      email: userInfo.email,
    };
    const settingPcr = await allRequestHandler({
      requestType: "PUT",
      requestUrl: URLS.SETPCRVALUE,
      requestData: pointsData,
    });

    if (pcrPoint !== "") {
      if (settingPcr.message == "Point value updated successfully") {
        setDisplayMsg("PCR percent updated successfully!");
        setHandleSnackbar(true);
        setSeverity("success");
        setOpenModal(false);
      } else {
        setDisplayMsg("Something went wrong");
        setHandleSnackbar(true);
        setSeverity("error");
      }
    } else {
      setDisplayMsg("Please enter some pcr percent value");
      setHandleSnackbar(true);
      setSeverity("error");
    }
    getUsers();
  };

  const approveSale = async () => {
    let approveSale = await allRequestHandler({
      requestType: "PUT",
      requestUrl: URLS.APPROVESALE,
      requestData: {
        id: approveSaleId,
        status: true,
      },
    });
    if (approveSale.message == "Payment approval status changed") {
      setDisplayMsg("Sent For approval");
      setHandleSnackbar(true);
      setSeverity("success");
      getSalesForApproval();
    }
    handleCloseModal();
    getSalesForApproval()
  };

  const approveWithdrawRequest = async () => {
    let approveWithdrawal = await allRequestHandler({
      requestType: "PUT",
      requestUrl: URLS.APPROVEWITHDRAWAL,
      requestData: {
        id: approveWithdrawId,
        status: true,
      },
    });

    if (approveWithdrawal.response == "payment approved") {
      setDisplayMsg("Request Approved");
      setHandleSnackbar(true);
      setSeverity("success");
      getAllWithdrawals();
    }
    handleCloseModal();
  };

  const blockUser = async () => {
    let blockuser = await allRequestHandler({
      requestType: "PUT",
      requestUrl: URLS.BLOCKUSER,
      requestData: {
        email: userInfo.email,
        status: false 
      },
    });
    if(blockuser.message.trim() == 'update successful'){
      setDisplayMsg("Person blocked")
      setHandleSnackbar(true)
      setSeverity("success")
     
    }
    handleCloseModal();
    getUsers()
    getBlockedVendors()
  };

  const unblockUser = async () => {
    let blockuser = await allRequestHandler({
      requestType: "PUT",
      requestUrl: URLS.BLOCKUSER,
      requestData: {
        email: userInfo.email,
        status: true
      },
    });
    if(blockuser.message.trim() == 'update successful'){
      setDisplayMsg("Unblocked successfully")
      setHandleSnackbar(true)
      setSeverity("success")  
    }
    handleCloseModal();
    getUsers()
    getBlockedVendors()
  };


  const setDeposit = async () => {
    const depositData = {
      amount: depositValue,
      email: userInfo.email,
    };
    const settingDeposit = await allRequestHandler({
      requestType: "POST",
      requestUrl: URLS.SETDEPOSIT,
      requestData: depositData,
    });

    if(depositData !== "") {
      if(settingDeposit.message == 'Amount deposited successfully'){
        setDisplayMsg("Deposit Amount updated")
        setHandleSnackbar(true)
        setSeverity("success")
        setOpenModal(false)
      }
      else {
        setDisplayMsg("Something went wrong")
        setHandleSnackbar(true)
        setSeverity("error")
    }
    }
    else {
      setDisplayMsg("Please enter some deposit value")
       setHandleSnackbar(true)
       setSeverity("error")
     }
     getUsers()
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        open={handleSnackbar}
        onClose={() => setHandleSnackbar(false)}
      >
        <MuiAlert elevation={6} variant="standard" severity={severity}>
          {" "}
          {displayMsg}{" "}
        </MuiAlert>
      </Snackbar>
      <div>
        <Button
          id="demo-positioned-button"
          aria-controls="demo-positioned-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <img src={MenuDot} />
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {menuOptions.map((option) => (
            <MenuItem
              onClick={() => showModal(option)}
              className={classes.typography14}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <div>
        {modalType == "Add Bonus Points" ? (
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.modalClass}>
              <Typography
                className={classes.typography32}
                style={{ padding: 15 }}
              >
                {modalTitle}
              </Typography>
              <Divider />
              <Grid container style={{ justifyContent: "left", padding: 20 }}>
                <Grid item>
                  <Typography className={classes.tableHeaderClass}>
                    Name
                  </Typography>
                  <Typography
                    className={classes.typography14}
                    style={{ textAlign: "center", marginRight: 30 }}
                  >
                    {userInfo.username }
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.tableHeaderClass}>
                    Email
                  </Typography>
                  <Typography
                    className={classes.typography14}
                    style={{ textAlign: "center" }}
                  >
                    {userInfo.email}
                  </Typography>
                </Grid>
              </Grid>
              <Grid style={{ padding: "0px 15px 35px 15px" }}>
                <Typography className={classes.labelName}>
                  Enter the points to be added
                </Typography>
                <CustomTextField
                  // fullWidth = {false}
                  type="number"
                  name="bonuspoint"
                  autoComplete="off"
                  autoFocus
                  BoxHeight="68px"
                  placeholder="Eg: 25"
                  handleChange={(e) => setBonusPoint(e.target.value)}
                />
                <Button className={classes.Btn} onClick={addPoints}>
                  Add Points
                </Button>
              </Grid>
            </Box>
          </Modal>
        ) : modalType == "Set PCR Value" ? (
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.modalClass}>
              <Typography
                className={classes.typography32}
                style={{ padding: 15 }}
              >
                Set PCR Value
              </Typography>
              <Divider />
              <Grid container style={{ justifyContent: "left", padding: 20 }}>
                <Grid item>
                  <Typography className={classes.tableHeaderClass}>
                    Name
                  </Typography>
                  <Typography
                    className={classes.typography14}
                    style={{ textAlign: "center" }}
                  >
                    {userInfo.username}
                  </Typography>
                </Grid>

                {/* <Grid item>
            <Typography className = {classes. tableHeaderClass}>
              Current PCR
            </Typography>
            <Typography  className = {classes. typography14}  style = {{ textAlign : "center"}}>
              0
            </Typography>
            </Grid> */}

                <Grid item>
                  <Typography className={classes.tableHeaderClass}>
                    Email
                  </Typography>
                  <Typography
                    className={classes.typography14}
                    style={{ textAlign: "center" }}
                  >
                    {userInfo.email}
                  </Typography>
                </Grid>
              </Grid>
              <Grid style={{ padding: "0px 15px 35px 15px" }}>
                <Typography className={classes.labelName}>
                  Enter the fixed PCR percentage for this vendor
                </Typography>
                <CustomTextField
                  // fullWidth = {false}
                  type="number"
                  name="bonuspoint"
                  autoComplete="off"
                  autoFocus
                  BoxHeight="68px"
                  placeholder="Eg: 30"
                  handleChange={(e) => setPcrPoint(e.target.value)}
                />
                <Button className={classes.Btn} onClick={setPcr}>
                  Set PCR Value
                </Button>
              </Grid>
            </Box>
          </Modal>
        ) : modalType == "Approve Sale" ? (
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.modalClass} style={{ padding: 15 }}>
              <Typography
                className={classes.typography32}
                style={{ padding: 15 }}
              >
                Are you sure you want to approve this sale ?
              </Typography>
              <Grid container style={{ justifyContent: "flex-end" }}>
                <Button className={classes.Btn} onClick={approveSale}>
                  Yes, Approve
                </Button>
                <Button className={classes.closeBtn} onClick={handleCloseModal}>
                  No, Dont
                </Button>
              </Grid>
            </Box>
          </Modal>
        ) : modalType == "Approve and mark as paid" ? (
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.modalClass} style={{ padding: 15 }}>
              <Typography
                className={classes.typography32}
                style={{ padding: 15 }}
              >
                Are you sure you want to approve this withdrawal request ?
              </Typography>
              <Grid container style={{ justifyContent: "flex-end" }}>
                <Button
                  className={classes.Btn}
                  onClick={approveWithdrawRequest}
                >
                  Yes, Approve
                </Button>
                <Button className={classes.closeBtn} onClick={handleCloseModal}>
                  No, Dont
                </Button>
              </Grid>
            </Box>
          </Modal>
        ) : modalType == "Block Vendor" || modalType == "Block User" ? (
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.modalClass} style={{ padding: 15 }}>
              <Typography
                className={classes.typography32}
                style={{ padding: 15 }}
              >
                {modalType == "Block Vendor"
                  ? "Are you sure you want to block this vendor ?"
                  : 
                  "Are you sure you want to block this user ?" 
                  }
              </Typography>
              <Grid container style={{ justifyContent: "flex-end" }}>
                <Button className={classes.Btn} onClick={blockUser}>
               Yes, block
                </Button>
                <Button className={classes.closeBtn} onClick={handleCloseModal}>
                  No, Dont
                </Button>
              </Grid>
            </Box>
          </Modal>
        ) : modalType == "Update Deposit Amount" ? (
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.modalClass}>
              <Typography
                className={classes.typography32}
                style={{ padding: 15 }}
              >
                Update Deposit Amount
              </Typography>
              <Divider />
              <Grid container style={{ justifyContent: "left", padding: 20 }}>
                <Grid item>
                  <Typography className={classes.tableHeaderClass}>
                    Name
                  </Typography>
                  <Typography
                    className={classes.typography14}
                    style={{ textAlign: "center" }}
                  >
                    {userInfo.username}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.tableHeaderClass}>
                    Email
                  </Typography>
                  <Typography
                    className={classes.typography14}
                    style={{ textAlign: "center" }}
                  >
                    {userInfo.email}
                  </Typography>
                </Grid>
              </Grid>
              <Grid style={{ padding: "0px 15px 35px 15px" }}>
                <Typography className={classes.labelName}>
                  Enter the Amount Deposited
                </Typography>
                <CustomTextField
                  // fullWidth = {false}
                  type="number"
                  name="bonuspoint"
                  autoComplete="off"
                  autoFocus
                  BoxHeight="68px"
                  placeholder="Eg: 30"
                  handleChange={(e) => setDepositValue(e.target.value)}
                />
                <Button className={classes.Btn} onClick={setDeposit}>
                  Update
                </Button>
              </Grid>
            </Box>
          </Modal>
        ) :
        modalType == "Unblock User" || "Unblock Vendor" ? 
        <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalClass} style={{ padding: 15 }}>
          <Typography
            className={classes.typography32}
            style={{ padding: 15 }}
          >
            {modalType == "Unblock Vendor"
              ? "Are you sure you want to unblock this vendor ?"
              : 
              "Are you sure you want to unblock this user ?" 
              }
          </Typography>
          <Grid container style={{ justifyContent: "flex-end" }}>
            <Button className={classes.Btn} onClick={unblockUser}>
           Yes, unblock
            </Button>
            <Button className={classes.closeBtn} onClick={handleCloseModal}>
              No, Dont
            </Button>
          </Grid>
        </Box>
      </Modal>
      :
        (
          <div></div>
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(MenuOptions);
