import React, { useState, useEffect } from "react";
import styles from "./profile.style";
import {
  Button,
  Grid,
  Typography,
  Avatar,
  withStyles,
  Snackbar,
} from "@material-ui/core";
import CustomTextField from "../customStyle/textfield";
import MuiAlert from "@material-ui/lab/Alert";
import { URLS } from "../../constants/index";
import { allRequestHandler } from "../../api";
import { Redirect } from "react-router-dom";
import Spinner from "../customStyle/spinner";
import moment from "moment";
const Profile = ({ classes }) => {
  const [userData, setUserData] = useState(null);

  const [mobileNo, setMobileNo] = useState(userData && userData.mobilenumber);
  const [bank, setBank] = useState(userData && userData.bank_name);
  const [bankAccNo, setBankAccNo] = useState(userData && userData.bank_account);
  const [ifscCode, setIfscCode] = useState(userData && userData.ifsc_code);
  const [address, setAddress] = useState(userData && userData.address);

  const [adhaar, setAdhaar] = useState(userData && userData.aadhar_number);
  const [pan, setPan] = useState(userData && userData.pan_number);

  const [profilePhoto, setProfilePhoto] = useState("");
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(false);

  const [handleSnackbar, setHandleSnackbar] = useState(null);
  const [displayMsg, setDisplayMsg] = useState(null);
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    const role = sessionStorage.getItem("role");
    if (role == "customer") {
      let custUserDetails = await allRequestHandler({
        requestType: "GET",
        requestUrl: URLS.CUSTOMERDASHBOARD,
      });
      if (custUserDetails.status == "401") setRedirect(true);
      else {
        setLoading(false);
        setUserData(custUserDetails.data);
        if (custUserDetails.dashboard_data.profile_picture !== "")
          setProfilePhoto(custUserDetails.dashboard_data.profile_picture.profile_pic);
      }
    }

    if (role == "vendor") {
      let venUserDetails = await allRequestHandler({
        requestType: "GET",
        requestUrl: URLS.VENDORDASHBOARD,
      });
      if (venUserDetails.status == "401") setRedirect(true);
      else {
        setLoading(false);
        setUserData(venUserDetails.data);
        if (venUserDetails.dashboard_data.profile_picture !== "")
        setProfilePhoto(venUserDetails.dashboard_data[0].profile_picture.profile_pic);
      }
    }
  };

  const clearPasswordForm = () => {
    setConfirmPassword("");
    setNewPassword("");
    setCurrentPassword("");
  };
  const updatePassword = async () => {
    const changepassword = await allRequestHandler({
      requestType: "PUT",
      requestUrl: URLS.PASSWORDRESET,
      requestData: {
        email: userData.email,
        old_password: currentPassword,
        new_password: newPassword,
        confirm_new_password: confirmPassword,
      },
    });

    if (
      currentPassword !== "" &&
      confirmPassword !== "" &&
      newPassword !== ""
    ) {
      if (changepassword.message == "Password updated successfully") {
        setHandleSnackbar(true);
        setDisplayMsg("Password updated successfully!");
        setSeverity("success");
        clearPasswordForm();
      } else {
        setHandleSnackbar(true);
        setDisplayMsg(changepassword.data.Error);
        setSeverity("error");
      }
    } else {
      setDisplayMsg("Password fields cannot be empty");
      setHandleSnackbar(true);
      setSeverity("error");
    }
  };

  const imageHandler = (e) => {
    setSelectedPhoto(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) setProfilePhoto(reader.result);
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const profileTextField = [
    {
      name: "Address",
      placeholder: "Enter your address",
      value: userData && userData.address ? userData.address : "",
      type: "text",
    },
    {
      name: "Mobile Number",
      placeholder: "Enter your mobile number",
      value: userData && userData.mobilenumber ? userData.mobilenumber : "",
      type: "number",
    },
    {
      name: "Bank",
      placeholder: "Enter your bank name",
      value: userData && userData.bank_name ? userData.bank_name : "",
      type: "text",
    },
    {
      name: "Account Number",
      placeholder: "Enter your bank account number",
      value: userData && userData.bank_account ? userData.bank_account : "",
      type: "text",
    },
    {
      name: "IFSC Code/ Swift Code",
      placeholder: "Enter your IFSC code",
      value: userData && userData.ifsc_code ? userData.ifsc_code : "",
      type: "text",
    },
    {
      name: "Aadhar Card Number",
      placeholder: "Enter your Aadhar",
      value: userData && userData.aadhar_number ? userData.aadhar_number : "",
      type: "text",
    },
    {
      name: "PAN Card Number",
      placeholder: "Enter your PAN",
      value: userData && userData.pan_number ? userData.pan_number : "",
      type: "text",
    },
  ];

  const handleChange = (e, name) => {
    name == "Address"
      ? setAddress(e.target.value)
      : name == "Mobile Number"
      ? setMobileNo(e.target.value)
      : name == "Bank"
      ? setBank(e.target.value)
      : name == "Account Number"
      ? setBankAccNo(e.target.value)
      : name == "Aadhar Card Number"
      ? setAdhaar(e.target.value)
      : name == "PAN Card Number"
      ? setPan(e.target.value)
      : name == "IFSC Code/ Swift Code"
      ? setIfscCode(e.target.value)
      : console.log("no val");
  };

  const updateProfile = async (e) => {
    e.preventDefault();
    const formData = {
      email : userData?.email,
      mobilenumber: mobileNo ? mobileNo : userData?.mobilenumber,
      bank_account: bankAccNo ? bankAccNo : userData?.bank_account,
      bankname: bank ? bank : userData?.bank_name,
      ifsccode: ifscCode ? ifscCode : userData?.ifsc_code,
      address: address ? address : userData?.address,
      aadhar_number: adhaar ? adhaar : userData?.aadhar_number,
      pan_number: pan ? pan : userData?.pan_number,
    };
    console.log(formData);
    const updateProfile = await allRequestHandler({
      requestType: "PUT",
      requestUrl: URLS.PROFILEUPDATE,
      requestData: formData,
    });
    if (updateProfile.message == "Update successful") {
      setIsEditing(false);
      setHandleSnackbar(true);
      setDisplayMsg("Profile updated");
      setSeverity("success");
      getUserDetails();
    }
  };

  const uploadPicture = async () => {
    const form_data = new FormData();
    form_data.append("profile_pic", selectedPhoto, selectedPhoto.name);

    const photo = await allRequestHandler({
      requestType: "IMAGE",
      requestUrl: URLS.PROFILEPICTURE,
      requestData: form_data,
    });
    if (photo.response == "Profile Picture updated successfully") {
      setHandleSnackbar(true);
      setDisplayMsg("Profile photo updated");
      setSeverity("success")
      window.location.reload()
    }
  };

  if (redirect) return <Redirect push to="/customer/login" />;

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            open={handleSnackbar}
            onClose={() => setHandleSnackbar(false)}
          >
            <MuiAlert elevation={6} variant="standard" severity={severity}>
              {" "}
              {displayMsg}{" "}
            </MuiAlert>
          </Snackbar>
          <Grid style={{ padding: 30 }}>
            <Grid
              className={classes.whiteCard}
              style={{
                padding: 40,
                display: "flex",
                justifyContent: "space-evenly",
                position: "relative",
              }}
            >
              <Grid style={{ width: "47%", borderRight: "2px solid #000" }}>
                <Grid className={classes.centerProfile}>
                  <Grid className={classes.orangeHeader}></Grid>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar className={classes.userAvtar}>
                      <label htmlFor="input" className="upload">
                        {!profilePhoto  ? (
                          <Typography className={classes.AvatarTypo}>
                            {" "}
                            {userData &&
                              userData.username.charAt(0).toUpperCase()}
                          </Typography>
                        ) : (
                          <img
                            style={{ cursor: "pointer" }}
                            alt="User Pic"
                            src={profilePhoto}
                            id="profile-image1"
                            height="110"
                          />
                        )}

                        <input
                          style={{ display: "none" }}
                          onChange={imageHandler}
                          name="image-upload"
                          id="input"
                          accept="image/*"
                          type="file"
                        ></input>
                      </label>
                    </Avatar>
                    <Button
                      className={classes.Btn}
                      style={{ marginTop: 40 }}
                      onClick={uploadPicture}
                    >
                      {" "}
                      Upload Picture{" "}
                    </Button>
                    <Typography
                      className={classes.typography20}
                      style={{ marginTop: 15 }}
                    >
                      {userData && userData.username}
                    </Typography>
                    <Typography
                      className={classes.typography17}
                      style={{ marginTop: 10 }}
                    >
                      {userData && userData.email}
                    </Typography>
                    <Typography
                      className={classes.roleTypo}
                      style={{ marginTop: 20 }}
                    >
                      {sessionStorage.getItem("role")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid style={{ width: "47%", marginTop: 40 }}>
                <form style={{ padding: 20, width: "95%" }}>
                  {profileTextField.map((field) => {
                    return (
                      <div style={{ marginBottom: 20, display: "flex" }}>
                        <Grid style={{ width: "50%" }}>
                          <Typography
                            className={classes.labelName}
                            style={{ fontSize: 18 }}
                          >
                            {field.name}
                          </Typography>
                        </Grid>
                        <Grid style={{ width: "50%" }}>
                          {isEditing ? (
                            <CustomTextField
                              fullWidth={true}
                              value={field.value}
                              type={field.type}
                              autoComplete="off"
                              autoFocus
                              BoxHeight="68px"
                              placeholder={field.placeholder}
                              handleChange={(e) => handleChange(e, field.name)}
                            />
                          ) : (
                            <Typography className={classes.typography17}>
                              {field.value == "" ? "-" : field.value}
                            </Typography>
                          )}
                        </Grid>
                      </div>
                    );
                  })}
                  {isEditing ? (
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        marginTop: "20px",
                      }}
                    >
                      <Button onClick={updateProfile} className={classes.Btn}>
                        Update Profile
                      </Button>
                      {/* <Button className = {classes.closeBtn}>Discard</Button> */}
                    </Grid>
                  ) : (
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        marginTop: "40px",
                      }}
                    >
                      <Button
                        onClick={() => setIsEditing(true)}
                        className={classes.Btn}
                      >
                        Edit Profile
                      </Button>
                      {/* <Button className = {classes.closeBtn}>Discard</Button> */}
                    </Grid>
                  )}
                </form>
              </Grid>
            </Grid>
            <Grid
              className={classes.whiteCard}
              style={{
                padding: 40,
              }}
            >
              <Typography
                className={classes.typography32}
                style={{ marginBottom: 15 }}
              >
                Reset Password
              </Typography>
              <Typography className={classes.labelName}>
                Current Password
              </Typography>
              <CustomTextField
                fullWidth={true}
                type="password"
                autoComplete="off"
                autoFocus
                BoxHeight="68px"
                placeholder="Current Password"
                handleChange={(e) => setCurrentPassword(e.target.value)}
              />
              <Typography className={classes.labelName}>
                New Password
              </Typography>
              <CustomTextField
                fullWidth={true}
                type="password"
                autoComplete="off"
                autoFocus
                BoxHeight="68px"
                placeholder="New Password"
                handleChange={(e) => setNewPassword(e.target.value)}
              />
              <Typography className={classes.labelName}>
                Confirm New Password
              </Typography>
              <CustomTextField
                fullWidth={true}
                type="password"
                autoComplete="off"
                autoFocus
                BoxHeight="68px"
                placeholder="New Password"
                handleChange={(e) => setConfirmPassword(e.target.value)}
              />

              <Button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px auto",
                }}
                onClick={updatePassword}
                className={classes.Btn}
              >
                Update Password
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(Profile);
