import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography, 
  withStyles,
  Snackbar
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CustomTextField from "../customStyle/textfield"
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import styles from "./login.style";
import { URLS } from "../../constants/index"
import { allRequestHandler } from "../../api";
import { useHistory, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux"

const VendorLogin = ({classes, setUserData}) => {

  const [email, setEmail ] = useState(null)
  const [password, setPassword ] = useState(null)
  const [redirect, setRedirect] = useState(null)
  const [handleSnackbar, setHandleSnackbar] = useState(null)
  const [displayMsg, setDisplayMsg] = useState(null)
  const [showPassword, setShowPassword ] = useState(false)
  const [btnToggle, setBtnToggle] = useState(false)
  useEffect(() => {
    sessionStorage.setItem("role", "vendor");
  },[])

  
  const logIn = async (e) => {
    
    e.preventDefault();
    if(email !== "" && password !== ""){
      let getToken = await allRequestHandler({
        requestType: "LOGIN",
        requestUrl: URLS.LOGIN,
        requestData: {
          username: email,
          password: password,
          portal : "vendor"
        },
      });

      setUserData(getToken.data.response)

      if (getToken.status == 200) {
        console.log(getToken.data.response)
        localStorage.setItem("loginData", JSON.stringify(getToken));
        sessionStorage.setItem("jwtToken", getToken.data.Token);
        sessionStorage.setItem("userId", getToken.data.response.userId);
        sessionStorage.setItem("vendorEmail", getToken.data.response.email)
        setRedirect(true)
      }
      else
      { 
        let validationMsg = getToken.data.error ? getToken.data.error :  getToken.data.response
        setDisplayMsg(validationMsg);
        setHandleSnackbar(true) 
        setBtnToggle(true)
        setRedirect(false)
      } 
    }
    else
     {
      setDisplayMsg("Please enter your crendentials")
      setHandleSnackbar(true)
      setBtnToggle(true)
      setRedirect(false)
      } 

  }

  const handleClickShowPassword = () => {
    setShowPassword(!setShowPassword);
  };
 
if (redirect) {
  return <Redirect push to="/vendor/dashboard" />;
}

return(
    <>
    <Grid className = {classes.outerLogin}>
      
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          open={handleSnackbar}
          onClose = {()=> setHandleSnackbar(false)}
        >
          <MuiAlert elevation={6} variant="standard" color="error"  severity="error">
            {" "}
            {displayMsg}{" "}
          </MuiAlert>
        </Snackbar>

      <Grid className = {classes.leftSection}>
       <Grid className = {classes.companyLogo}>
         FOBiz
         <Typography style = {{
         color : "#26184D",
         padding : "10px 20px",
         background : "#E0632B",
         borderRadius : 8,
         fontFamily : "Ubuntu",
         textTransform : "uppercase",
       }}>
         LOGIN AS VENDOR
       </Typography>
       </Grid>
    
      </Grid>
        <Grid className = {classes.loginBox}>
        <Typography style ={{
          fontSize : 35,
          fontFamily : "Ubuntu",
          fontWeight : 600,
          margin : 10
        }}>Log in</Typography>
       
       <Grid className={classes.login}>    
            <form onSubmit={(e) => logIn(e)}>
                <div style={{ marginBottom: 20 }}>
                  <Typography className={classes.labelName}>
                    Email
                  </Typography>
                  <CustomTextField
                    id="email"
                    name="email"
                    type = "text"
                    autoComplete="off"
                    autoFocus
                    BoxHeight={"38px"}
                    handleChange = { (e) => setEmail(e.target.value)}
                  />
                </div> 
                <div>
                  <Typography className={classes.labelName}>
                    Password
                  </Typography>
                  <CustomTextField
                    name="password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    BoxHeight={"38px"}
                    handleChange = {(e) => setPassword(e.target.value)}
                    // unit={
                    //   showPassword ? (
                    //     <Visibility style={{ cursor: "pointer" }} />
                    //   ) : (
                    //     <VisibilityOff style={{ cursor: "pointer" }} />
                    //   )
                    // }
                    // clickIcon={handleClickShowPassword}
                  />
                    <Link to="/vendor/forgotPassword">
                  <Typography style = {{
                    color : "#346BD4",
                    fontSize : 14,
                    fontFamily : "Ubuntu",
                    marginTop : "25px"
                  }}>Forgot Password ?</Typography>
                   </Link>
                </div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.loginBtn}
                >
                  Log in
                </Button>
              </form>
        </Grid> 
        <Typography className={classes.labelName}>
          Dont have an account ? 
          <Link to = "/vendor/signup">
          <span style = {{
                    color : "#346BD4",
                    fontSize : 14,
                    fontFamily : "Ubuntu",
                    margin : "5px 5px 0",
                    textDecoration : "none"
                  }}>
            Register Here 
          </span>
          </Link>
        </Typography>
        </Grid> 
    </Grid>
    </>
  ) 
}

const mapDispatchToProps = (dispatch) => {
  return {
   setUserData : (userData) => {
     dispatch({  type : 'SETUSERDATA', userData : userData  }) }
    }  
  }

export default connect(null, mapDispatchToProps)(withStyles(styles)(VendorLogin));
