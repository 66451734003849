export default {
typography32 : {
    fontSize : 20,
    color : "#26184D",
    fontWeight : 600,
    fontFamily : "Ubuntu"
 },

 typography20 : {
   fontSize : 18,
   color : "#E0632B",
   fontWeight : 500,
   fontFamily : "Ubuntu"
},

 descTypo : {
  fontSize : 14,
  margin : "5px 0",
  fontFamily : "Ubuntu",
  color : "#26184D",
  lineHeight : 1.5
 },


 orderCard : {
    height : "auto",
    width : "90%",
    padding : 20,
    background : "#FFFFFF",
    margin : "20px",
    borderRadius : 12,
    fontFamily : "Ubuntu",
    boxShadow: 
    `inset 0 0 15px rgba(55, 84, 170,0),
    inset 0 0 20px rgba(255, 255, 255,0),
    7px 7px 15px rgba(55, 84, 170,.15),
    -7px -7px 20px rgba(255, 255, 255,1),
    inset 0px 0px 4px rgba(255, 255, 255,.2)`
 },


}