export default {
outerLogin : {
    height : "100vh", 
    width : "100%", 
    background : "#F0F4FA",
    display : "flex",
},
leftSection : {
height : "100vh",
width : "50%",
background : "#26184D",
"@media(max-width: 920px)":{
    display : "none"
  }
},
companyLogo : {
    color : "#E0632B",
    fontSize : 135,
    fontFamily : "Poppins",
    textTransform : "capitalize",
    margin : "0 auto",
    display : "flex",
    flexDirection : "column",
    alignItems : "center",
    justifyContent : "center",
    position: "absolute",
    top: "30%",
    left : "10%",
    transform: "translateY(-50%, -50%)",
    letterSpacing : "7px"
},
loginBox : {
    margin : "0 auto",
    height : "50vh",
    width : "50%",
    display : "flex",
    flexDirection :"column",
    justifyContent : "center",
    alignItems : "center",
    position: "relative",
    top: "20%",
    // left : "10%",
    transform: "translateY(-50%, -50%)",

},
login : {
    height : "262px",
    width : "50%",
    background : "#FFFFFF", 
    borderRadius : 10,
    padding : 30,
   
},
labelName: {
    fontSize: 14,
    fontFamily:"Ubuntu",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "20px",
    color: "#536471",
    margin : "5px 0"
},
loginBtn: {
    margin: "24px 0",
    top: "42.95%",
    bottom: "53.11%",
    height: 50,
    background: "#26184D",
    boxShadow: "0px 2px 4px rgba(0, 132, 255, 0.2)",
    borderRadius: 8,
    textTransform : "uppercase",
    fontWeight : 500,
    fontFamily : "Ubuntu"
},
}