export default {
    SidebarDiv: {
      backgroundColor: "rgba(38, 24, 77, 0.95)",
      height: "100%",
      borderRadius : 15,
      // position : "fixed",
      "@media(max-width: 920px)":{
        display : "none"
      }
    },
    sideBarNav: {
      margin: 10,
      background: "linear-gradient(180deg, rgba(255, 255, 255, 0.525) 0%, rgba(255, 255, 255, 0.225) 100%)",
      padding : "12px 0",
      borderRadius : 25,
      height : "82vh"
    },
    ProjectDiv: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    sideBarLink: {
      height: 30,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: 206,
      padding: 10,
      margin : "13px 0",
    },
    LogoDiv: {
      width: 108,
      height: 24,
      padding: "20px 18px",
      margin : "0 auto"
    },
    typography14: {
      fontFamily: "Ubuntu",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 15,
    },
    sidebarIcons : {
      margin : 20,
      color : "#FFFFFF",
      height : 22
    }
  };
  