import React, {useState, useEffect} from "react";
import {
    Grid, Typography, Button,Snackbar
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./adminDashboard.style";
import Card from "../../customStyle/cards"
import CustomTextField from "../../customStyle/textfield"
import { URLS } from "../../../constants/index"
import { allRequestHandler } from "../../../api"
import MuiAlert from "@material-ui/lab/Alert";
import {  Redirect } from "react-router-dom";
import Spinner from "../../customStyle/spinner"
  
const Dashboard = ({classes}) => { 
    const [point, setPoint] = useState(null)
    const [handleSnackbar, setHandleSnackbar] = useState(null)
    const [displayMsg, setDisplayMsg] = useState(null)
    const [severity, setSeverity] = useState("")
    const [dashboardDetails, setDashboardDetails] = useState(null)
    const [redirect, setRedirect] = useState(null)
    const [loading, setLoading] = useState(true)

    const adminCards = [
        {
            title : "Total Users",
            key :  dashboardDetails ? dashboardDetails.total_users : 0,
        },
        {
            title : "Total Vendors",
            key : dashboardDetails ? dashboardDetails.total_vendors : 0
        },
        {
            title : "Sale Requests",
            key :  dashboardDetails ? dashboardDetails.total_sales : 0
        },
        {
            title : "Withdrawl Requests",
            key :  dashboardDetails ? dashboardDetails.total_withdraw_request : 0
        },
        {
            title : "FOBiz Earnings",
            key : dashboardDetails ? dashboardDetails.fobizearnings : 0,
            currency : "₹"
        },
        {
            title : "Current Point Value",
            key : dashboardDetails ? dashboardDetails.pcr_value : 0
        },
    ]

    useEffect(() => {
        getDashboardDetails()
      },[])
    const updatePointValue = async() => {
        let updatePoint = await allRequestHandler({
            requestType : "PUT",
            requestUrl : URLS.UPDATEPOINT,
            requestData : {
                points : point
            }
          })
    if(point !== ""){
          if(updatePoint.points_value) {
            setDisplayMsg("Point value updated successfully!")
            setHandleSnackbar(true) 
            setSeverity("success")
          }
        else {
            setDisplayMsg("Something went wrong")
            setHandleSnackbar(true) 
            setSeverity("error")
        }
    }
    else {
           setDisplayMsg("Please enter some point value")
            setHandleSnackbar(true) 
            setSeverity("error")
      }

      getDashboardDetails()
    }

    const getDashboardDetails = async() => {
        let dashboardDetails = await allRequestHandler({
            requestType : "GET",
            requestUrl : URLS.ADMINDASHBOARD
          });
          if(dashboardDetails.status == '401') 
          setRedirect(true) 
          else {
          setLoading(false)
          setDashboardDetails(dashboardDetails.dashboard_data)
          sessionStorage.setItem("customerEmail", dashboardDetails.data.email)
          sessionStorage.setItem("referralCode", dashboardDetails.data.referral_code)
          }
    }

    if(redirect)
     return <Redirect push to = '/admin/login' />
    
    return(
      <>
      {
        loading ? 
        <Spinner /> :
        <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          open={handleSnackbar}
          onClose = {()=> setHandleSnackbar(false)}
        >
          <MuiAlert elevation={6} variant="standard"   severity={severity}>
            {" "}
            {displayMsg}{" "}
          </MuiAlert>
        </Snackbar>
        <Grid  className = {classes.outerContainer}>
            <Grid>
            <Typography className= {classes.typography32}>
                Complete Statistics
            </Typography>
            </Grid>
          <Grid className = {classes.cardList}>

             { 
              adminCards.map(item => {
              let key = item.key
              return (                
              <Grid item>
                <Card 
                 currency = {item.currency}
                 title = {item.title}
                 amount = {item.key}
                />
                </Grid>
              
                
               ) } )
            }
          </Grid>
          </Grid>

          <Grid  className = {classes.outerContainer}>
          <Typography className= {classes.typography32}>
                Points Value
            </Typography>
            <Grid style={{ margin: "40px 60px"}}>
            <Typography className={classes.labelName}>
              Enter the new Point value
          </Typography>
            <CustomTextField
            // fullWidth = {false}
            type = "number"
            name="pcrpoint"
            autoComplete="off"
            autoFocus
            BoxHeight="68px"
            placeholder = "Eg: 25"
            handleChange = { (e) => setPoint(e.target.value)}
          />   
          <Grid style={{ display:"flex", justifyContent : "center", margin : 10}}>
          <Button className = {classes.Btn} onClick = {updatePointValue}>
          Update Point Value
          </Button> 
          </Grid>
          
          </Grid>  
          </Grid>
        </>
      }
      </>

      
    )
}



export default withStyles(styles)(Dashboard)