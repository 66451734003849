import * as React from 'react';
// import CircularProgress from '@mui/material/CircularProgress';
import { Box, CircularProgress } from '@material-ui/core';

const  Spinner = () => {
  return (
    <Box style={{ position : "absolute", top : "50%", left : "50%", transform : "translate(-50%, -50%)" }}>
      <CircularProgress
       style= {{
           color : '#E0632B'
       }}
      />
    </Box>
  );
} 

export default Spinner