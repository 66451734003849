
const requestUrl = "https://api.fobiz.net/v1";

export const URLS = {
    //auth
    LOGIN : requestUrl + "/api/login",
    REGISTER : requestUrl + "/api/auth-register/",
    LOGOUT : requestUrl + "/api/logout",
    FORGOTPASSWORDTOKEN : requestUrl + "/api/password_reset/",
    FORGOTPASSWORD : requestUrl + "/api/password_reset/confirm/",
    PASSWORDRESET : requestUrl + "/api/change-password/",
    
   //customer
   CUSTOMERDASHBOARD : requestUrl + "/api/user/dashboard/",
   REFERALS : requestUrl + "/api/user/referrals",
   PAYMENTS : requestUrl + "/api/user/withdraw/",
   WITHDRAW : requestUrl + "/api/user/withdraw/",
   POINTS : requestUrl + "/api/user/points/",
   PROFILEUPDATE : requestUrl + "/api/users/update/",
   PROFILEPICTURE : requestUrl + "/api/uploadpicture",
   
    //vendor
    SALESFORM : requestUrl + "/api/vendor/sale",
    GETSALES : requestUrl + "/api/vendor/sales/",
    VENDORDASHBOARD : requestUrl + "/api/vendor/dashboard",
    GENERATEINVOICE : requestUrl + "/api/vendor/invoice/",

    //admin
    GETUSERS : requestUrl + "/api/getallusers",
    APPROVESALE : requestUrl + "/api/vendor/sale",
    UPDATEPOINT : requestUrl + "/api/pcrpoints/",
    ADMINDASHBOARD : requestUrl + "/api/dashboard/",
    ADDBONUSPOINT : requestUrl + "/api/user/points/",
    SETPCRVALUE : requestUrl + "/api/points",
    GETWITHDRAWALS : requestUrl + "/api/getallwithdrawals/",
    APPROVEWITHDRAWAL : requestUrl + "/api/user/withdraw/",
    BLOCKUSER : requestUrl + "/api/blockuser",
    SETDEPOSIT : requestUrl + "/api/vendor/deposit",
    GETBLOCKEDUSERS : requestUrl + "/api/blockuser"
}