export default {
    whiteCard : {
        minHeight: "400px",
        height : "auto",
        width : "90%",
        padding : 10,
        background : "#FFFFFF",
        margin : "20px",
        borderRadius : 12,
        fontFamily : "Ubuntu",
        boxShadow: 
        `inset 0 0 15px rgba(55, 84, 170,0),
        inset 0 0 20px rgba(255, 255, 255,0),
        7px 7px 15px rgba(55, 84, 170,.15),
        -7px -7px 20px rgba(255, 255, 255,1),
        inset 0px 0px 4px rgba(255, 255, 255,.2)`
    },

    typography32 : {
       fontSize : 20,
       color : "#26184D",
       fontWeight : 600,
       fontFamily : "Ubuntu"
    },
    viewBtn : {
        fontSize : 20,
        color : "#26184D",
        fontWeight : 600,
        padding : "10px 20px",
        background : "#f0cdbd",
        borderRadius : 12,
        cursor : "pointer",
        fontFamily : "Ubuntu"
      },
      tableContainer : {
        height : "300px",
        // overflow: "scroll",
        borderRadius : 20,
        margin : "20px 0",
        // width : "80%",
        // margin : "10px auto"
    },

    typography14 : {
       fontSize : 14,
       color : "#26184D",
       fontWeight : 400,
       fontFamily : "Ubuntu",
       fontStyle : "normal"
    },
    Btn : {
        textTransform : "capitalize",
        fontFamily : "Ubuntu",
        fontSize : 14,
        margin : "0px 5px",
        color : "#FFF",
        fontWeight : 500,
        background :  "#E0632B",
        padding : "5px 25px",
        borderRadius : 6,
        border : "3px solid #E0632B",
        cursor : "pointer",
        "&:hover" : {
            background :  "#E0632B",
            boxShadow: 
            `inset 0 0 15px rgba(55, 84, 170,0),
            inset 0 0 20px rgba(255, 255, 255,0),
            7px 7px 15px rgba(55, 84, 170,.15),
            -7px -7px 20px rgba(255, 255, 255,1),
            inset 0px 0px 4px rgba(255, 255, 255,.2)`
        }
     },
      
     linkContainer : {
         background : "rgba(38, 24, 77, 0.2)",
         padding : "10px",
         borderRadius : 5,
         width: "50%"
     }
    }