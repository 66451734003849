import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./vendordash.style";
import Card from "../customStyle/cards";
import { URLS } from "../../constants/index";
import { allRequestHandler } from "../../api";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Redirect } from "react-router-dom";
import Spinner from "../customStyle/spinner";

const Dashboard = ({ classes }) => {
  const salesChartData = [
    {
      heading: "Approved Sales",
      desc: "This is the number of sales approved by the admin.",
    },
    {
      heading: "Sales On Hold",
      desc: "This is the number of sales yet to be apporved by the admin.",
    },
  ];

  const [dashboardDetails, setDashboardDetails] = useState(null);
  const [percent, setPercent] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDashboardDetails();
  }, []);

  const vendorCards = [
    {
      title: "Total Sales",
      key: dashboardDetails ? dashboardDetails.totalsale : 0,
    },
    {
      title: "Sales on hold",
      key: dashboardDetails ? dashboardDetails.total_sale_hold : 0,
    },
    {
      title: "Approved Sales",
      key: dashboardDetails ? dashboardDetails.total_approve_sale : 0,
    },
    {
      title: "Total Earnings",
      key: dashboardDetails ? dashboardDetails.TotalIncome : 0,
      currency: "₹",
    },
  ];

  const getDashboardDetails = async () => {
    let dashboardDetails = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.VENDORDASHBOARD,
    });

    if (dashboardDetails.status == "401") setRedirect(true);
    else {
      setLoading(false);
      setDashboardDetails(dashboardDetails.dashboard_data[0]);
    
      sessionStorage.setItem("vendorEmail", dashboardDetails.data.email);
      if (dashboardDetails && dashboardDetails[0]) {
        let approvePercent =
          (dashboardDetails[0].total_approve_sale /
            dashboardDetails[0].totalsale) *
          100;
        let onHoldPercent =
          (dashboardDetails[0].total_sale_hold /
            dashboardDetails[0].totalsale) *
          100;

        setPercent([approvePercent, onHoldPercent]);
      }
    }
  };
  if (redirect) return <Redirect push to="/vendor/login" />;
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Grid style={{ display: "flex" }}>
            <Grid className={classes.outerContainer}>
              <Grid>
                <Typography className={classes.typography32}>
                  Complete Statistics
                </Typography>
              </Grid>
              <Grid className={classes.cardList}>
                {vendorCards.map((item) => (
                  <Grid item>
                    <Card
                      currency={item.currency}
                      title={item.title}
                      amount={item.key ? item.key : 0}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid className={classes.outerContainer}>
              <Typography className={classes.typography32}>
                Deposit Amount
              </Typography>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height : "70%"
                }}
              >
                <Grid
                  style={{
                    width: "80%",
                  }}
                >
                  <Card currency={"₹"} title="Amount Left" amount={dashboardDetails?.debit_info?.debited_advance} />
                </Grid>
              </Grid>

              {/* <Grid style = {{ display : "flex", flexDirection : "column", justifyContent : "space-evenly"}}>
                   {
                     percent && percent.length > 0 && percent.map(per =>
                         <div style={{ width: 100, height: 100, margin : 10 }}>
                         <CircularProgressbar value={per} text={`${per}%`}
                         styles={buildStyles({
                             pathColor: "#E0632B",
                             textColor: "#26184D",
                             trailColor: "#26184D",
                             fontFamily : "Ubuntu"  
                           })}
                           strokeWidth= {10} />
                         </div>)
                        }
                           
                   </Grid> */}
              {/* <Grid  style = {{ display : "flex", flexDirection : "column", justifyContent : "space-around"}}>
                 {
                     salesChartData.map(data => (
                     <Grid style = {{ margin : "40px 10px 40px 20px"}}>
                     <Typography className = {classes.typography32}>{data.heading}</Typography>
                     <Typography className = {classes.chartDesc}>{data.desc}</Typography>
                      </Grid>   
                     ))
                 } 
              </Grid> */}
            </Grid>
          </Grid>

          <Grid
            className={classes.outerContainer}
            style={{
              width: "94%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
          >
            <Typography className={classes.typography32} style={{ margin: 12 }}>
              Help / Assistance
            </Typography>
            <Grid style={{ margin: 12 }}>
              <Typography
                style={{
                  fontFamily: "Ubuntu",
                }}
              >
                <span style={{ margin: 12, marginTop: 5 }}>
                  <svg
                    width="28"
                    height="20"
                    viewBox="0 0 28 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H26C26.5304 20 27.0391 19.7893 27.4142 19.4142C27.7893 19.0391 28 18.5304 28 18V2C28 1.46957 27.7893 0.960859 27.4142 0.585786C27.0391 0.210714 26.5304 0 26 0ZM23.8 2L14 8.78L4.2 2H23.8ZM2 18V2.91L13.43 10.82C13.5974 10.9361 13.7963 10.9984 14 10.9984C14.2037 10.9984 14.4026 10.9361 14.57 10.82L26 2.91V18H2Z"
                      fill="black"
                    />
                  </svg>
                </span>
                Email: support@fobiz.net
              </Typography>
            </Grid>
            <Grid style={{ margin: 12 }}>
              <Typography
                style={{
                  fontFamily: "Ubuntu",
                }}
              >
                <span style={{ margin: 12 }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.25 6.99981C15.8787 6.99981 17 8.12106 17 10.7498H19.5C19.5 6.71856 17.2812 4.49981 13.25 4.49981V6.99981ZM17.5275 13.8036C17.2873 13.5853 16.9717 13.4688 16.6472 13.4789C16.3228 13.4889 16.015 13.6246 15.7887 13.8573L12.7975 16.9336C12.0775 16.7961 10.63 16.3448 9.13999 14.8586C7.64999 13.3673 7.19874 11.9161 7.06499 11.2011L10.1387 8.20856C10.3718 7.98247 10.5077 7.67459 10.5177 7.35007C10.5277 7.02555 10.4111 6.70986 10.1925 6.46981L5.57374 1.39106C5.35504 1.15026 5.05108 1.00419 4.72643 0.983885C4.40177 0.963579 4.08198 1.07063 3.83499 1.28231L1.12249 3.60856C0.906375 3.82545 0.777386 4.11412 0.759986 4.41981C0.741236 4.73231 0.383736 12.1348 6.12374 17.8773C11.1312 22.8836 17.4037 23.2498 19.1312 23.2498C19.3837 23.2498 19.5387 23.2423 19.58 23.2398C19.8856 23.2227 20.1741 23.0931 20.39 22.8761L22.715 20.1623C22.9268 19.9155 23.0341 19.5958 23.014 19.2711C22.9939 18.9465 22.8481 18.6424 22.6075 18.4236L17.5275 13.8036Z"
                      fill="black"
                    />
                  </svg>
                </span>
                Call us:  992-577-5742 
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(Dashboard);
