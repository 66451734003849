import React from "react"
import {withStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";

const styles = () => ({
cardContainer : {
    height : "auto",
    padding : "20px 15px",
    // background: "rgb(240,205,189)",
    // background: "linear-gradient(4deg, rgba(240,205,189,1) 25%, rgba(224,99,43,1) 99%)",
    borderRadius : 5,
    // width : 300,
    background: "rgb(240,205,189)",
    background: "linear-gradient(4deg, rgba(240,205,189,0.6898109585631127) 25%, rgba(224,99,43,0.4449930313922444) 99%)",
    display : "flex",
    flexDirection : "column",
    alignItems : "center",
    boxShadow: 
    `inset 0 0 15px rgba(55, 84, 170,0),
    inset 0 0 20px rgba(255, 255, 255,0)
    7px 7px 15px rgba(55, 84, 170,.15),
    -7px -7px 20px rgba(255, 255, 255,1),
    inset 0px 0px 4px rgba(255, 255, 255,.2)`
    
},

amountTypography : {
    fontWeight : 700,
    color : "#26184D",
    fontSize : 21,
    fontStyle : "normal",
    letterSpacing : 1,
    fontFamily : "Ubuntu"
},

titleTypography : {
    fontWeight : 500,
    fontSize : 15,
    color : "#26184D",
    fontStyle : "normal",
    fontFamily : "Ubuntu"
    // letterSpacing : 1
}
})


class card extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <>
            <Grid className= { classes.cardContainer} style = { this.props.style}>
             <Typography className = {classes.amountTypography}><span style = {{ fontSize : 23}}>{this.props.currency}</span>
             {Number(this.props.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
             </Typography>
            <Typography className = {classes.titleTypography}>{this.props.title}</Typography>
            </Grid>
            </>
        )
    }
}


export default withStyles(styles)(card)