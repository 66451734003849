import React from "react";
import { Link } from "react-router-dom";
import styles from "./sidebar.style";
import { Typography, withStyles } from "@material-ui/core";
import Logo from "../../assets/dashboard_logo.svg"
import DashboardImg from "../../assets/dashboardsidebar.svg";
import ReportImg from "../../assets/reportsidebar.svg";
import OrderImg from "../../assets/ordersidebar.svg";
import PaymentImg from "../../assets/paymentsidebar.svg";
import ReferralImg from "../../assets/referralsidebar.svg";
import ProfileImg from "../../assets/profilesidebar.svg";
import SalesImg from "../../assets/salessidebar.svg"
import LogoutImg from "../../assets/logoutsidebar.svg";
import UserImg from "../../assets/usersidebar.svg";
import VendorImg from "../../assets/vendorsidebar.svg";
import SalesApprovalImg from "../../assets/salesapprovalsidebar.svg";
import PointsPanelImg from "../../assets/pointspanelsidebar.svg";
import SalesReportImg from "../../assets/salesreportsidebar.svg";


const Sidebar = ({ classes }) => { 
  const path = window.location.pathname;

  const Nav = ({ name, pathname, icon }) => {
    return (
      <div className={classes.ProjectDiv}>
        <div>
          <Link
            to={pathname}
            style={{ textDecoration: "none"}}
          >
            <div
              className={classes.sideBarLink}
              style={{
                background:
                  path === pathname
                    ? "rgba(224, 99, 43, 0.5)"
                    : "unset",
                borderLeft: 
                  path === pathname ?
                  "7px solid #E0632B" : "unset",
              }}
            >
              <img src={icon} alt="" className={classes.sidebarIcons} style = {{
                color :
                 path === pathname
                 ? "rgba(224, 99, 43, 0.5)"
                 : "unset"
              }}/>
              <Typography
                className={classes.typography14}
                style={{ color: path === pathname ?  "#26184D" : "#FFFFFF" }}
              >
                {name}
              </Typography>
            </div>
          </Link>
        </div>
      </div>
    );
  };

  const CustomerNav = 
    <>
    <Nav name="Dashboard" pathname="/customer/dashboard" icon={DashboardImg} />
    <Nav name="Reports" pathname="/customer/reports" icon={ReportImg} />
    <Nav name="Orders" pathname="/customer/orders" icon={OrderImg} />
    <Nav name="Referrals" pathname="/customer/referrals" icon={ReferralImg} />
    <Nav name="Payments" pathname="/customer/payments" icon={PaymentImg} />
    <Nav name="Profile" pathname="/customer/profile" icon={ProfileImg} />    
    {/* <Nav name="Logout" pathname="/customer/login" icon={LogoutImg}  /> */}
    </>

  const VendorNav = 
  <>
     <Nav name="Dashboard" pathname="/vendor/dashboard" icon={DashboardImg} /> 
     <Nav name = "Sales" pathname="/vendor/sales" icon = {SalesImg} />
     <Nav name="Profile" pathname="/vendor/profile" icon={ProfileImg} />    
    {/* <Nav name="Logout" pathname="/vendor/login" icon={LogoutImg} /> */}
  </>

  const AdminNav =
  <>    
        <Nav name="Dashboard" pathname="/admin/dashboard" icon={DashboardImg} /> 
        <Nav name="Users" pathname="/admin/users" icon={UserImg} />
        <Nav name="Vendors" pathname="/admin/vendors" icon={VendorImg} />
        <Nav name="Sales Approval" pathname="/admin/sales-approval" icon={SalesApprovalImg} />
        <Nav name="Withdrawal Requests" pathname="/admin/withdrawal-requests" icon={OrderImg} />
        <Nav name="Settings" pathname="/admin/settings" icon={ProfileImg} />
        {/* <Nav name="Sales Report" pathname="admin/sales-report" icon ={SalesReportImg} /> */} 
         {/* <Nav name="Logout" pathname="/admin/login" icon={LogoutImg}  /> */}
  </>

 
  return (
    <div className={classes.SidebarDiv}>
      <div className={classes.LogoDiv}>
      <Typography style = {{ fontFamily : "Ubuntu", color : "#E0632B", fontSize : 36, marginBottom : 10}}>FOBiz</Typography>
      </div>
      <div className={classes.sideBarNav}>
      {
        sessionStorage.role == "customer" ? 
        CustomerNav
        :
        sessionStorage.role == "vendor" ?
        VendorNav
        :
        AdminNav
      }
      </div>
    </div>
  );
};

export default withStyles(styles)(Sidebar);
