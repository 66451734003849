
 export default {
     outerContainer : {
         height : "auto",
         width : "50%",
         padding : 20,
         background : "#FFFFFF",
         margin : "20px",
         borderRadius : 12,
         fontFamily : "Ubuntu",
         boxShadow: 
         `inset 0 0 15px rgba(55, 84, 170,0),
         inset 0 0 20px rgba(255, 255, 255,0),
         7px 7px 15px rgba(55, 84, 170,.15),
         -7px -7px 20px rgba(255, 255, 255,1),
         inset 0px 0px 4px rgba(255, 255, 255,.2)`
     },

     typography32 : {
        fontSize : 20,
        color : "#26184D",
        fontWeight : 600,
        fontFamily : "Ubuntu"
     },
      
     cardList : {
        margin: "10 auto",
        display : "grid",
        padding : "20px",
        gridGap : "20px",
        "@media(min-width: 768px)":{
           gridTemplateColumns : "repeat(2,1fr)"
        },
        "@media(min-width: 960px)":{
             gridTemplateColumns : "repeat(2,1fr)"
          },
          "@media(min-width: 1200px)":{
             gridTemplateColumns : "repeat(2,1fr)"
          } 
    },

    chartDesc : {
       width : "80%",
       fontFamily : "Ubuntu",
       fontSize : 14 
    },
    viewBtn : {
      fontSize : 20,
      color : "#26184D",
      fontWeight : 600,
      padding : "10px 20px",
      background : "#f0cdbd",
      borderRadius : 12,
      cursor : "pointer",
      fontFamily : "Ubuntu",
      background: "rgb(240,205,189)",
      background: "linear-gradient(4deg, rgba(240,205,189,0.6898109585631127) 25%, rgba(224,99,43,0.4449930313922444) 99%)",
    }
 }