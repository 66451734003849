import React, { useState, useEffect } from "react";
import styles from "../../Profile/profile.style";
import {
  Button, 
  Grid,
  Typography,
  Avatar,
  withStyles,
  Snackbar,
} from "@material-ui/core";
import CustomTextField from "../../customStyle/textfield";
import MuiAlert from "@material-ui/lab/Alert";
import { URLS } from "../../../constants/index";
import { allRequestHandler } from "../../../api/index";
import { Redirect } from "react-router-dom";
import Spinner from "../../customStyle/spinner";
import moment from "moment";
import { Settings } from "@material-ui/icons";
const Setting = ({ classes }) => {
 
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [email, setEmail] = useState(null)
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null)
  const [handleSnackbar, setHandleSnackbar] = useState(null);
  const [displayMsg, setDisplayMsg] = useState(null);
  const [severity, setSeverity] = useState("");

 
  useEffect(() => {
    getUserDetails()
  },[])
  const updatePassword = async () => {
    const changepassword = await allRequestHandler({
      requestType: "PUT",
      requestUrl: URLS.PASSWORDRESET,
      requestData: {
        email: userData.email,
        old_password: currentPassword,
        new_password: newPassword,
        confirm_new_password: confirmPassword,
      },
    });

    if (
      currentPassword !== "" &&
      confirmPassword !== "" &&
      newPassword !== ""
    ) {
      if (changepassword.message == "Password updated successfully") {
        setHandleSnackbar(true);
        setDisplayMsg("Password updated successfully!");
        setSeverity("success");
      } else {
        setHandleSnackbar(true);
        setDisplayMsg(changepassword.data.Error);
        setSeverity("error");
      }
    } else {
      setDisplayMsg("Password fields cannot be empty");
      setHandleSnackbar(true);
      setSeverity("error");
    }
  };

  const getUserDetails = async () => {
    const role = sessionStorage.getItem("role");
   
  
    if(role == "admin"){
        let adminUserDetails = await allRequestHandler({
          requestType : "GET",
          requestUrl : URLS.ADMINDASHBOARD
        });
        if(adminUserDetails){
          setUserData(adminUserDetails.data)
          } 
        }
  };
 

 
  if (redirect) return <Redirect push to="/admin/login" />;

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            open={handleSnackbar}
            onClose={() => setHandleSnackbar(false)}
          >
            <MuiAlert elevation={6} variant="standard" severity={severity}>
              {" "}
              {displayMsg}{" "}
            </MuiAlert>
          </Snackbar>
          <Grid style={{ padding: 20 }}>
         
            <Grid
              className={classes.whiteCard}
              style={{
                padding: 40,
              }}
            >
              <Typography
                className={classes.typography32}
                style={{ marginBottom: 15 }}
              >
                Reset Password
              </Typography>
              
              <Typography className={classes.labelName}>
                Current Password
              </Typography>
              <CustomTextField
                fullWidth={true}
                type="password"
                autoComplete="off"
                autoFocus
                BoxHeight="68px"
                placeholder="Current Password"
                handleChange={(e) => setCurrentPassword(e.target.value)}
              />
              <Typography className={classes.labelName}>
                New Password
              </Typography>
              <CustomTextField
                fullWidth={true}
                type="password"
                autoComplete="off"
                autoFocus
                BoxHeight="68px"
                placeholder="New Password"
                handleChange={(e) => setNewPassword(e.target.value)}
              />
              <Typography className={classes.labelName}>
                Confirm New Password
              </Typography>
              <CustomTextField
                fullWidth={true}
                type="password"
                autoComplete="off"
                autoFocus
                BoxHeight="68px"
                placeholder="New Password"
                handleChange={(e) => setConfirmPassword(e.target.value)}
              />

              <Button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px auto",
                }}
                onClick={updatePassword}
                className={classes.Btn}
              >
                Update Password
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(Setting);
