import React, { useState, useLayoutEffect, useEffect } from "react";
import {
  Typography,
  Grid,
  Menu,
  MenuItem,
} from "@material-ui/core";
import styles from "./header.style";
import { withStyles } from "@material-ui/core/styles";
import { withRouter, useLocation, Redirect } from "react-router-dom";
import { URLS } from "../../constants/index"
import { allRequestHandler } from "../../api";
import { connect } from "react-redux";


const Header = ({ classes, title, }) => {

const [anchorEl, setAnchorEl] = useState(false);
const [logout, setLogout ] = useState(false);
const [goToProfile, setGoToProfile] = useState(false)
const [userData, setUserData] = useState(null)
const [profilePhoto, setProfilePhoto] = useState(null)

useEffect(() => {
  getUserDetails()
},[])

const getUserDetails = async() => {
  const role = sessionStorage.getItem('role')
  if(role == "customer") {
    let custUserDetails = await allRequestHandler({
      requestType : "GET",
      requestUrl : URLS.CUSTOMERDASHBOARD
    });
    if(custUserDetails){
      setUserData(custUserDetails.data)
      if (custUserDetails.dashboard_data.profile_picture !== "")
      setProfilePhoto(custUserDetails.dashboard_data.profile_picture.profile_pic);
      } 
  }

  if(role == "vendor"){
    let venUserDetails = await allRequestHandler({
      requestType : "GET",
      requestUrl : URLS.VENDORDASHBOARD
    });
    if(venUserDetails){
      setUserData(venUserDetails.data)
      if (venUserDetails.dashboard_data.profile_picture !== "")
      setProfilePhoto(venUserDetails.dashboard_data[0].profile_picture.profile_pic);
      } 
  }
 
  if(role == "admin"){
    let adminUserDetails = await allRequestHandler({
      requestType : "GET",
      requestUrl : URLS.ADMINDASHBOARD
    });
    if(adminUserDetails){
      setUserData(adminUserDetails.data)
      } 
  }

     
  }
const handleMenu = (event) => {
 setAnchorEl(event.currentTarget);
 };
  
 const handleMenuClose = () => {
 setAnchorEl(null);
 };

 const Logout = async() => {
  let getToken = await allRequestHandler({
    requestType: "GET",
    requestUrl: URLS.LOGOUT,
  });
  console.log(getToken)
  if(getToken.message == "User logged out") {
   sessionStorage.setItem("jwtToken", null)
   setLogout(true)
  }
}


 const location = useLocation().pathname;

  if(logout) 
  {
  const role = sessionStorage.getItem('role')
  if(role == "customer")  return <Redirect exact push to = "/customer/login" /> 
  if(role == "vendor")  return <Redirect exact push to = "/vendor/login" /> 
  if(role == "admin") return <Redirect exact push to = "/admin/login" /> 
  }
  // if(goToProfile) return  <Redirect exact push to = "/customer/profile" /> 
  return (
    <Grid
      container 
      alignItems="center"
      justify="space-between"
      className={classes.outerHeader}
    >
      <Grid item>  
  <Typography className={classes.title}>{location.substring(location.lastIndexOf('/') + 1).split('-').join(' ')}</Typography>
      </Grid>
      <Grid item>
        <Grid
          container
          alignItems="center"
          style={{ cursor: "pointer" }}
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={handleMenu}
        >
            <Typography className={classes.menuProfile} style = {{ marginRight : 15, color : "#26184D"}}>
          { userData && userData.username ? userData.username : ""}
          </Typography>
          {!profilePhoto ? (
                          <Typography className={classes.initialName}>
                            {" "}
                            {userData &&
                              userData.username.charAt(0).toUpperCase()}
                          </Typography>
                        ) : (
                          <img
                            style={{ cursor: "pointer", width: 50,
                            height: 50,
                            borderRadius: 72, }}
                            alt="User Pic"
                            src={profilePhoto}
                            id="profile-image1"
                            height="110"
                          />
                        )}
          {/* <Typography className={classes.initialName}>{userData && userData.username ? userData.username.charAt(0).toUpperCase() : ""}</Typography> */}
        </Grid>
      </Grid>

      {/* Dropdown Menu */}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        classes={{
          paper: classes.menuOuter,
        }}
      >
        <Grid container direction="column" className={classes.menuInner}>
          <Typography className={classes.menuProfile}>
          { userData && userData.username ? userData.username : ""}
          </Typography>
          <Typography className={classes.menuEmail}>
          {userData && userData.email ? userData.email : ""}
          </Typography>
        </Grid>
        {/* <MenuItem className={classes.menuItem} onClick = {() => setGoToProfile(true)}>
          My Profile
        </MenuItem> */}
        <MenuItem className={classes.menuItem} onClick = {Logout}>
          Logout
        </MenuItem>
      </Menu>
    </Grid>
  ); 
};


export default withRouter(withStyles(styles)(Header));
