import React, { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  Link,
} from "react-router-dom";
import { Grid } from "@material-ui/core";
import CustomerLogin from "./views/login/customer_index";
import VendorLogin from "./views/login/vendor_index";
import AdminLogin from "./views/login/admin_index";
import ForgotPassword from "./views/forgotPassword/index";
import Dashboard from "./views/Dashboard";
import Orders from "./views/Orders";
import Payments from "./views/Payments";
import Profile from "./views/Profile";
import Referrals from "./views/Referrals";
import Report from "./views/Reports";
import Sales from "./views/sales";
import VendorDashboard from "./views/VendorDashboard";
import Users from "./views/admin/users";
import Vendors from "./views/admin/vendors";
import SalesApproval from "./views/admin/salesApproval";
import AdminDashboard from "./views/admin/adminDashboard";
import Settings from "./views/admin/setting";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import CustomerRegister from "./views/registerUser/customer_register_index";
import VendorRegister from "./views/registerUser/vendor_register_index";
import WithdrawalRequests from "./views/admin/withdrawalRequests";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { SettingsApplications } from "@material-ui/icons";
const App = () => {
  const [user, setUser] = useState(null);
  return (
    <>
      <Router>
        <Switch>
          <Route
            exact
            path="/customer/login"
            render={() => <CustomerLogin />}
          />
          <Route exact path="/vendor/login" render={() => <VendorLogin />} />
          <Route exact path="/admin/login" render={() => <AdminLogin />} />
          <Route
            exact
            path="/customer/signup"
            render={() => <CustomerRegister />}
          />
          <Route
            exact
            path="/vendor/signup"
            render={() => <VendorRegister />}
          />
            <Route
            exact
            path="/customer/forgotpassword"
            render={() => <ForgotPassword />}
          />
          <Route
            exact
            path="/vendor/forgotpassword"
            render={() => <ForgotPassword />}
          />

          <Grid container style={{ height: "100vh", background: "#F0F4FA" }}>
            <Grid item style={{ margin: 15 }}>
              <Route
                exact
                path={`/customer/dashboard`}
                render={() => <Sidebar />}
              />
              <Route
                exact
                path={`/customer/orders`}
                render={() => <Sidebar />}
              />
              <Route
                exact
                path={`/customer/reports`}
                render={() => <Sidebar />}
              />
              <Route
                exact
                path={`/customer/profile`}
                render={() => <Sidebar />}
              />
              <Route
                exact
                path={`/customer/payments`}
                render={() => <Sidebar />}
              />
              <Route
                exact
                path={`/customer/referrals`}
                render={() => <Sidebar />}
              />

              <Route
                exact
                path={`/vendor/dashboard`}
                render={() => <Sidebar />}
              />
              <Route exact path={`/vendor/sales`} render={() => <Sidebar />} />
              <Route
                exact
                path={`/vendor/profile`}
                render={() => <Sidebar />}
              />

              <Route
                exact
                path={`/admin/dashboard`}
                render={() => <Sidebar />}
              />
              <Route exact path={`/admin/users`} render={() => <Sidebar />} />
              <Route exact path={`/admin/vendors`} render={() => <Sidebar />} />
              <Route
                exact
                path={`/admin/sales-approval`}
                render={() => <Sidebar />}
              />
              <Route
                exact
                path={`/admin/withdrawal-requests`}
                render={() => <Sidebar />}
              />
            <Route exact path={`/admin/settings`} render={() => <Sidebar />} /> 
            </Grid>

            <Grid
              item
              xs
              style={{
                background: "#F0F4FA",
                padding: 13,
                overflowY: "scroll",
                height: "100vh",
              }}
            >
              <Header />
              <Route
                exact
                path={`/customer/dashboard`}
                render={() => <Dashboard />}
              />
              <Route
                exact
                path={`/customer/orders`}
                render={() => <Orders />}
              />
              <Route
                exact
                path={`/customer/reports`}
                render={() => <Report />}
              />
              <Route
                exact
                path={`/customer/profile`}
                render={() => <Profile />}
              />
              <Route
                exact
                path={`/customer/payments`}
                render={() => <Payments />}
              />
              <Route
                exact
                path={`/customer/referrals`}
                render={() => <Referrals />}
              />

              <Route
                exact
                path={`/vendor/dashboard`}
                render={() => <VendorDashboard />}
              />
              <Route exact path={`/vendor/sales`} render={() => <Sales />} />
              <Route
                exact
                path={`/vendor/profile`}
                render={() => <Profile />}
              />

              <Route
                exact
                path={`/admin/dashboard`}
                render={() => <AdminDashboard />}
              />
              <Route exact path={`/admin/users`} render={() => <Users />} />
              <Route exact path={`/admin/vendors`} render={() => <Vendors />} />
              <Route
                exact
                path={`/admin/sales-approval`}
                render={() => <SalesApproval />}
              />
              <Route
                exact
                path={`/admin/withdrawal-requests`}
                render={() => <WithdrawalRequests />}
              />
            
             <Route exact path={`/admin/settings`} render={() => <Settings /> } /> 
            </Grid>
          </Grid>
        </Switch>
      </Router>
    </>
  );
};

export default App;
