export default {
  labelName: {
    fontSize: 14,
    fontFamily: "Ubuntu",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "20px",
    color: "#E0632B",
    margin: "5px 0",
  },
  outerContainer: {
    minHeight: "300px",
    height: "auto",
    // width : "50%",
    padding: 20,
    background: "#FFFFFF",
    margin: "20px",
    borderRadius: 12,
    fontFamily: "Ubuntu",
    boxShadow: `inset 0 0 15px rgba(55, 84, 170,0),
        inset 0 0 20px rgba(255, 255, 255,0),
        7px 7px 15px rgba(55, 84, 170,.15),
        -7px -7px 20px rgba(255, 255, 255,1),
        inset 0px 0px 4px rgba(255, 255, 255,.2)`,
  },

  typography32: {
    fontSize: 20,
    color: "#26184D",
    fontWeight: 600,
    fontFamily: "Ubuntu",
  },

  typography14: {
    fontSize: 13,
    color: "#26184D",
    fontWeight: 400,
    fontFamily: "Ubuntu",
    fontStyle: "normal",
  },

  Btn: {
    // marginLeft : 130,
    textTransform: "capitalize",
    fontFamily: "Ubuntu",
    fontSize: 17,
    color: "#FFF",
    fontWeight: 500,
    background: "#E0632B",
    padding: "5px 15px",
    borderRadius: 6,
    border: "3px solid #E0632B",
    cursor: "pointer",
    "&:hover": {
      background: "#E0632B",
      boxShadow: `inset 0 0 15px rgba(55, 84, 170,0),
            inset 0 0 20px rgba(255, 255, 255,0),
            7px 7px 15px rgba(55, 84, 170,.15),
            -7px -7px 20px rgba(255, 255, 255,1),
            inset 0px 0px 4px rgba(255, 255, 255,.2)`,
    },
  },

  cardList: {
    margin: "10 auto",
    display: "grid",
    padding: "20px",
    gridGap: "40px",
    "@media(min-width: 768px)": {
      gridTemplateColumns: "repeat(2,1fr)",
    },
    "@media(min-width: 960px)": {
      gridTemplateColumns: "repeat(2,1fr)",
    },
    "@media(min-width: 1200px)": {
      gridTemplateColumns: "repeat(3,1fr)",
    },
  },

  cardContainer: {
    height: "auto",
    padding: "20px 15px",
    background: "#f0cdbd",
    borderRadius: 5,
    width: 270,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: `inset 0 0 15px rgba(55, 84, 170,0),
        inset 0 0 20px rgba(255, 255, 255,0),
        7px 7px 15px rgba(55, 84, 170,.15),
        -7px -7px 20px rgba(255, 255, 255,1),
        inset 0px 0px 4px rgba(255, 255, 255,.2)`,
    cursor: "pointer",
  },

  amountTypography: {
    fontWeight: 700,
    color: "#26184D",
    fontSize: 21,
    fontStyle: "normal",
    letterSpacing: 1,
    fontFamily: "Ubuntu",
    margin: 5,
  },

  titleTypography: {
    fontWeight: 500,
    fontSize: 15,
    color: "#26184D",
    fontStyle: "normal",
    fontFamily: "Ubuntu",
    margin: 5,
    // letterSpacing : 1
  },

  //Table styles
  tableContainer: {
    height: "500px",
    margin: "20px 0",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },

  tableHeaderClass: {
    fontSize: 16,
    color: "#26184D",
    fontWeight: 600,
    fontFamily: "Ubuntu",
    border: "none",
    background: "#f2bea6",
    // textAlign : "center",
    padding: 18,
    // borderRadius: 10
  },
 
  tableCellClass: {
    fontSize: 14,
    color: "#7f7f7f",
    letterSpacing: "normal",
    fontWeight: 500,
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    // borderBottom : "none",
    textTransform: "capitalize",
    // textAlign : "center",
    padding: 20,
  },

  tableRowClass: {
    "&:hover": {
      background: "rgba(224, 99, 43, 0.15)",
    },
  },
  approvedStatus: {
    color: "#FFF",
    background: "#026c45",
    padding: "5px 15px",
    borderRadius: 6,
  },
  onHoldStatus: {
    color: "#FFF",
    background: "#be0000",
    padding: "5px 15px",
    borderRadius: 6,
  },
};
