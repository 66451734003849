
export default {
  typography32: {
    fontSize: 20,
    color: "#26184D",
    fontWeight: 600,
    fontFamily: "Ubuntu",
  },
  typography20: {
    fontSize: 20,
    color: "#26184D",
    fontWeight: 500,
    fontFamily: "Ubuntu",
  },
  typography17: {
    fontSize: 18,
    color: "rgba(38, 24, 77, 0.4)",
    fontWeight: 400,
    fontFamily: "Ubuntu",
  },
  typography14: {
    fontSize: 13,
    color: "#26184D",
    fontWeight: 400,
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 500,
  },
  whiteCard: {
    height: "auto",
    width: "90%",
    padding: 10,
    background: "#FFFFFF",
    margin: "20px",
    borderRadius: 12,
    fontFamily: "Ubuntu",
    boxShadow: `inset 0 0 15px rgba(55, 84, 170,0),
        inset 0 0 20px rgba(255, 255, 255,0),
        7px 7px 15px rgba(55, 84, 170,.15),
        -7px -7px 20px rgba(255, 255, 255,1),
        inset 0px 0px 4px rgba(255, 255, 255,.2)`,
  },
  Btn: {
    textTransform: "capitalize",
    fontFamily: "Ubuntu",
    fontSize: 14,
    color: "#FFF",
    margin: "0px 5px",
    fontWeight: 500,
    background: "#E0632B",
    padding: "5px",
    borderRadius: 6,
    border: "3px solid #E0632B",
    cursor: "pointer",
    "&:hover": {
      background: "#E0632B",
      boxShadow: `inset 0 0 15px rgba(55, 84, 170,0),
          inset 0 0 20px rgba(255, 255, 255,0),
          7px 7px 15px rgba(55, 84, 170,.15),
          -7px -7px 20px rgba(255, 255, 255,1),
          inset 0px 0px 4px rgba(255, 255, 255,.2)`,
    },
  },
  roleTypo: {
    textTransform: "capitalize",
    fontFamily: "Ubuntu",
    fontSize: 14,
    color: "#FFF",
    margin: "0px 5px",
    fontWeight: 500,
    background: "#E0632B",
    padding: "5px 15px",
    borderRadius: 6,
    border: "3px solid #E0632B",
  },
  closeBtn: {
    textTransform: "capitalize",
    fontFamily: "Ubuntu",
    fontSize: 14,
    margin: "0px 5px",
    color: "#E0632B",
    fontWeight: 500,
    background: "#fff",
    padding: "5px 15px",
    borderRadius: 6,
    border: "3px solid #E0632B",
    cursor: "pointer",
  },
  viewBtn: {
    fontSize: 20,
    color: "#26184D",
    fontWeight: 600,
    padding: "10px 20px",
    background: "#f0cdbd",
    borderRadius: 12,
    cursor: "pointer",
    fontFamily: "Ubuntu",
  },
  userAvtar: {
    width: 90,
    height: 90,
    border: "3px solid #E0632B",
    borderRadius: 20,
    background: "#FFF",
    position: "absolute",
    top: 50,
    cursor : "pointer"
  },
  AvatarTypo: {
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 28,
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#e0632b",
    cursor : "pointer"
  },
  labelName: {
    fontSize: 14,
    fontFamily: "Ubuntu",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "20px",
    color: "#E0632B",
    margin: "5px 0",
  },
  centerProfile: {
    position: "absolute",
    top: "50%",
    left: "25%",
    transform: "translate(-50%, -50%)",
    borderRadius: 6,
    height: 360,
    width: "40%",
    boxShadow: `inset 0 0 15px rgba(55, 84, 170,0),
      inset 0 0 20px rgba(255, 255, 255,0),
      7px 7px 15px rgba(55, 84, 170,.15),
      -7px -7px 20px rgba(255, 255, 255,1),
      inset 0px 0px 4px rgba(255, 255, 255,.2)`,
  },
  orangeHeader: {
    background: "#E0632B",
    top: 0,
    height: 100,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    boxShadow: `inset 0 0 15px rgba(55, 84, 170,0),
      inset 0 0 20px rgba(255, 255, 255,0),
      7px 7px 15px rgba(55, 84, 170,.15),
      -7px -7px 20px rgba(255, 255, 255,1),
      inset 0px 0px 4px rgba(255, 255, 255,.2)`,
    padding: 7,
  },
};
