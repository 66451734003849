import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./users.style";
import {
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Box,
  Modal,
  Checkbox,
  Snackbar,
  Divider,
} from "@material-ui/core";
import MenuOptions from "../menuOptions";
import { URLS } from "../../../constants/index";
import { allRequestHandler } from "../../../api";
import MuiAlert from "@material-ui/lab/Alert";
import noDataImg from "../../../assets/search.png";
import { Redirect } from "react-router-dom";
import Spinner from "../../customStyle/spinner";
import SearchBar from "../../customStyle/searchBar";
import moment from "moment";
import CustomTextField from "../../customStyle/textfield";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const ExpandableTableRow = ({
  children,
  expandComponent,
  classes,
  index,
  ...otherProps
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell
          padding="checkbox"
          style={
            index % 2
              ? { background: "#fff", borderBottom: "none" }
              : { background: "rgba(224, 99, 43, 0.25)", borderBottom: "none" }
          }
        >
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell
            padding="checkbox"
            style={
              index % 2
                ? { background: "#fff", borderBottom: "none" }
                : {
                    background: "rgba(224, 99, 43, 0.25)",
                    borderBottom: "none",
                  }
            }
          />
          {expandComponent}
        </TableRow>
      )}
    </>
  );
};
const Users = ({ classes }) => {
  const [users, setUsers] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(true);
  const [blockedUsers, setBlockedUsers] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchUserTerm, setSearchUserTerm] = useState("");
  const [searchBlockedUserTerm, setSearchBlockedUserTerm] = useState("");

  const [mobileNo, setMobileNo] = useState(null);
  const [place, setPlace] = useState(null);
  const [bank, setBank] = useState(null);
  const [bankAccNo, setBankAccNo] = useState(null);
  const [ifscCode, setIfscCode] = useState(null);
  const [adhaar, setAdhaar] = useState(null);
  const [pan, setPan] = useState(null);

  const [bulkPoints, setBulkPoints] = useState([]);
  const [allChecked, setAllChecked] = useState([]);
  const [bonusPoint, setBonusPoint] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [handleSnackbar, setHandleSnackbar] = useState(null);
  const [displayMsg, setDisplayMsg] = useState(null);
  const [severity, setSeverity] = useState("");

  const [selectMultiple, setSelectMultiple] = useState(false);
  const [allSelected, setAllSelected] = useState(false);

  const [searchBy, setSearchBy] = useState("");
  const [blockedSearchBy, setBlockedSearchBy] = useState("");

  useEffect(() => {
    getUsers();
    getBlockedUsers();
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const showModal = () => {
    setOpenModal(true);
  };

  const handleChange = (e, name) => {
    name == "Bank"
      ? setBank(e.target.value)
      : name == "Account Number"
      ? setBankAccNo(e.target.value)
      : name == "Aadhar Card Number"
      ? setAdhaar(e.target.value)
      : name == "PAN Card Number"
      ? setPan(e.target.value)
      : name == "IFSC Code"
      ? setIfscCode(e.target.value)
      : name == "Mobile Number"
      ? setMobileNo(e.target.value)
      : name == "Place"
      ? setPlace(e.target.value)
      : console.log("no val");
  };

  const getUsers = async () => {
    let userData = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.GETUSERS,
      //  "?userid=" + sessionStorage.getItem("userId"),
    });

    if (userData.status == "401") setRedirect(true);
    else {
      setLoading(false);
      setUsers(userData.customer);
    }
  };

  const getBlockedUsers = async () => {
    let blockeduserData = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.GETBLOCKEDUSERS,
    });

    if (blockeduserData.status == "401") setRedirect(true);
    else {
      console.log(blockeduserData.response);
      setLoading(false);
      setBlockedUsers(blockeduserData.response);
    }
  };

  const updateUserDetails = async (row) => {
    const formData = {
      email: row.email,
      mobilenumber: mobileNo !== null ? mobileNo : row.mobilenumber,
      bank_account: bankAccNo !== null ? bankAccNo : row.bank_account,
      bankname: bank !== null ? bank : row.bankname,
      ifsccode: ifscCode !== null ? ifscCode : row.ifsccode,
      address: place !== null ? place : row.address,
      aadhar_number: adhaar !== null ? adhaar : row.aadhar_number,
      pan_number: pan !== null ? pan : row.pan_number,
    };
    console.log(formData);
    const updateProfile = await allRequestHandler({
      requestType: "PUT",
      requestUrl: URLS.PROFILEUPDATE,
      requestData: formData,
    });
    if (updateProfile.message == "Update successful") {
      setDisplayMsg("User Profile Updated");
      setHandleSnackbar(true);
      setSeverity("success");
      getUsers();
      window.location.reload()
    } else {
      setDisplayMsg("Something went wrong");
      setHandleSnackbar(true);
      setSeverity("error");
    }
  };

  const handleCheckBox = (e, email) => {
    if (e.target.checked) setBulkPoints([...bulkPoints, email]);
    else setBulkPoints(bulkPoints.filter((em) => em !== email));
  };

  const handleSelectAllCheckbox = (e) => {
    let allEmails = !allSelected
      ? users && users.length > 0 && users.map((user) => user.email)
      : [];
    console.log(allEmails);
    setBulkPoints(allEmails);
  };

  const handleSelectAll = () => {
    setAllSelected(!allSelected);
    handleSelectAllCheckbox();
  };

  const addPoints = async () => {
    const pointsData = {
      points: bonusPoint,
      emailid: bulkPoints,
    };
    const addingPoints = await allRequestHandler({
      requestType: "POST",
      requestUrl: URLS.ADDBONUSPOINT,
      requestData: pointsData,
    });
    console.log(addingPoints);

    if (bonusPoint !== "") {
      if (addingPoints) {
        setDisplayMsg("Bonus Points added!");
        setHandleSnackbar(true);
        setSeverity("success");
        setOpenModal(false);
        setBulkPoints([]);
        setAllSelected(false);
      } else {
        setDisplayMsg("Something went wrong");
        setHandleSnackbar(true);
        setSeverity("error");
      }
    } else {
      setDisplayMsg("Please enter some point value");
      setHandleSnackbar(true);
      setSeverity("error");
    }
  };

  const filteredUser =
    searchBy == "Name"
      ? users &&
        users.length > 0 &&
        users.filter((user) => {
          return user?.username
            ?.toLowerCase()
            .includes(searchUserTerm.toLowerCase());
        })
      : searchBy == "Email"
      ? users &&
        users.length > 0 &&
        users.filter((user) => {
          return user?.email
            ?.toLowerCase()
            .includes(searchUserTerm.toLowerCase());
        })
      : searchBy == "Place/Area"
      ? users &&
        users.length > 0 &&
        users.filter((user) => {
          return user?.address && user.address
            ?.toLowerCase()
            .includes(searchUserTerm.toLowerCase());
        })
      : searchBy == "Mobile Number"
      ? users &&
        users.length > 0 &&
        users.filter((user) => {
          return user?.mobilenumber?.toString().toLowerCase()
            .includes(searchUserTerm.toString());
        })
      : users;

  const filteredBlockUser =
    blockedSearchBy == "Name"
      ? blockedUsers &&
        blockedUsers.length > 0 &&
        blockedUsers.filter((blockedusers) => {
          return blockedusers?.username
            ?.toLowerCase()
            .includes(searchBlockedUserTerm.toLowerCase());
        })
      : blockedSearchBy == "Email"
      ? blockedUsers &&
        blockedUsers.length > 0 &&
        blockedUsers.filter((blockedusers) => {
          return blockedusers.email
            ?.toLowerCase()
            .includes(searchBlockedUserTerm.toLowerCase());
        })
      : blockedSearchBy == "Place/Area"
      ? blockedUsers &&
        blockedUsers.length > 0 &&
        blockedUsers.filter((blockedusers) => {
          return blockedusers.address
            ?.toLowerCase()
            .includes(searchBlockedUserTerm.toLowerCase());
        })
      : blockedSearchBy == "Mobile Number"
      ? blockedUsers &&
        blockedUsers.length > 0 &&
        blockedUsers.filter((blockedusers) => {
          return blockedusers.mobilenumber
            ?.toLowerCase()
            .includes(searchBlockedUserTerm.toLowerCase());
        })
      : blockedUsers;

  const headings = [
    "",
    "Joining date",
    "Name",
    "Email",
    "Place/Area",
    "Mobile Number",
    "",
  ];

  const headings2 = [
    "Joining date",
    "Name",
    "Email",
    "Place/Area",
    "Mobile Number",
    "",
  ];

  const profileTextField = [
    {
      name: "Mobile Number",
      placeholder: "Enter mobile no",
      key: "mobilenumber",
      type: "number",
    },
    {
      name: "Place",
      placeholder: "Enter place",
      key: "place",
      type: "text",
    },
    {
      name: "Bank",
      placeholder: "Enter bank name",
      key: "bankname",
      type: "text",
    },
    {
      name: "Account Number",
      placeholder: "Enter  bank account number",
      key: "bank_account",
      type: "text",
    },
    {
      name: "IFSC Code",
      placeholder: "Enter IFSC code",
      key: "ifsccode",
      type: "text",
    },
    {
      name: "Aadhar Card Number",
      placeholder: "Enter Aadhar",
      key: "aadhar_number",
      type: "text",
    },
    {
      name: "PAN Card Number",
      placeholder: "Enter PAN",
      key: "pan_number",
      type: "text",
    },
  ];

  if (redirect) return <Redirect push to="/admin/login" />;

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        open={handleSnackbar}
        onClose={() => setHandleSnackbar(false)}
      >
        <MuiAlert elevation={6} variant="standard" severity={severity}>
          {" "}
          {displayMsg}{" "}
        </MuiAlert>
      </Snackbar>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {users && users.length > 0 ? (
            <Grid className={classes.outerContainer}>
              <Grid
                container
                style={{ justifyContent: "space-between", marginBottom: 7 }}
              >
                <Grid item>
                  <Typography className={classes.typography32}>
                    List of users<span style={{  color : "#7e7e7e", fontWeight: 400}}> ({users && users.length})</span>
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <SearchBar
                        searchBy={searchBy}
                        setSearchBy={setSearchBy}
                        menuItems={headings2.slice(1, -1)}
                        keyword={searchUserTerm}
                        placeholder="Search by email"
                        setKeyword={setSearchUserTerm}
                      />
                    </Grid>
                    <Grid item>
                      <a
                        href="https://api.fobiz.net/v1/api/excel/customers"
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        <Button className={classes.Btn}>
                          Export to excel
                        </Button>{" "}
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              <Grid container style={{ marginTop: 15 }}>
              <Grid item>
                 <Typography className={classes.typography32} style={{ fontWeight: 400, fontSize:16, color : "#7e7e7e", marginTop : 5, marginRight: 5}}>Selected({bulkPoints?.length})</Typography>
                </Grid>
                <Grid item>
                  <Button className={classes.Btn} onClick={handleSelectAll}>
                    {allSelected ? "Deselect All" : "Select All"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button className={classes.Btn} onClick={showModal}>
                    Add Bulk Points
                  </Button>
                </Grid>
              </Grid>

              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className={classes.tableHeaderClass}
                      >
                        {""}
                      </TableCell>

                      {headings.map((heading) => (
                        <TableCell
                          className={classes.tableHeaderClass}
                          style={{ borderBottom: "none" }}
                        >
                          {heading}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredUser &&
                      filteredUser.length > 0 &&
                      filteredUser.map((row, index) => (
                        <>
                          <ExpandableTableRow
                            key={row.email}
                            index={index}
                            expandComponent={
                              <TableCell
                                colSpan="7"
                                className={classes.tableCellClass}
                                style={
                                  index % 2
                                    ? { background: "#fff" }
                                    : { background: "rgba(224, 99, 43, 0.25)" }
                                }
                              >
                                {profileTextField.map((field) => {
                                  return (
                                    <div
                                      style={{
                                        marginBottom: 10,
                                        display: "flex",
                                      }}
                                    >
                                      <Grid style={{ width: "30%" }}>
                                        <Typography
                                          className={classes.tableCellClass}
                                          style={{
                                            padding: 0,
                                            fontWeight: 500,
                                          }}
                                        >
                                          {field.name}
                                        </Typography>
                                      </Grid>
                                      <Grid style={{ width: "50%" }}>
                                        <CustomTextField
                                          fullWidth={false}
                                          value={
                                            field.key == "bankname"
                                              ? row.bankname
                                                ? row.bankname
                                                : "-"
                                              : field.key == "bank_account"
                                              ? row.bank_account
                                                ? row.bank_account
                                                : "-"
                                              : field.key == "ifsccode"
                                              ? row.ifsccode
                                                ? row.ifsccode
                                                : "-"
                                              : field.key == "aadhar_number"
                                              ? row.aadhar_number
                                                ? row.aadhar_number
                                                : "-"
                                              : field.key == "pan_number"
                                              ? row.pan_number
                                                ? row.pan_number
                                                : "-"
                                              : field.key == "place"
                                              ? row.address
                                                ? row.address
                                                : "-"
                                              : field.key == "mobilenumber"
                                              ? row.mobilenumber
                                                ? row.mobilenumber
                                                : "-"
                                              : "-"
                                          }
                                          type={field.type}
                                          autoComplete="off"
                                          autoFocus
                                          BoxHeight="68px"
                                          placeholder={field.placeholder}
                                          handleChange={(e) =>
                                            handleChange(e, field.name)
                                          }
                                        />
                                      </Grid>
                                    </div>
                                  );
                                })}

                                <Grid
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    width: "100%",
                                    marginTop: "10px",
                                    marginLeft: "0px",
                                  }}
                                >
                                  <Button
                                    onClick={() => updateUserDetails(row)}
                                    className={classes.Btn}
                                    style={{ marginLeft: 0 }}
                                  >
                                    Update User Details
                                  </Button>
                                </Grid>
                              </TableCell>
                            }
                          >
                            <TableCell
                              className={classes.tableCellClass}
                              style={
                                index % 2
                                  ? { background: "#fff" }
                                  : { background: "rgba(224, 99, 43, 0.25)" }
                              }
                            >
                              <Checkbox
                                color="default"
                                onChange={(e) => handleCheckBox(e, row.email)}
                                checked={bulkPoints.includes(row.email)}
                              />
                            </TableCell>

                            <TableCell
                              className={classes.tableCellClass}
                              style={
                                index % 2
                                  ? { background: "#fff" }
                                  : { background: "rgba(224, 99, 43, 0.25)" }
                              }
                            >
                              {row.date_joined
                                ? moment(row.date_joined).format("MMM Do YY")
                                : "-"}
                            </TableCell>
                            <TableCell
                              className={classes.tableCellClass}
                              style={
                                index % 2
                                  ? { background: "#fff" }
                                  : { background: "rgba(224, 99, 43, 0.25)" }
                              }
                            >
                              {row.username ? row.username : "-"}
                            </TableCell>
                            <TableCell
                              className={classes.tableCellClass}
                              style={
                                index % 2
                                  ? { background: "#fff" }
                                  : { background: "rgba(224, 99, 43, 0.25)" }
                              }
                            >
                              {row.email ? row.email : "-"}
                            </TableCell>
                            <TableCell
                              className={classes.tableCellClass}
                              style={
                                index % 2
                                  ? { background: "#fff" }
                                  : { background: "rgba(224, 99, 43, 0.25)" }
                              }
                            >
                              {row.address ? row.address : "-"}
                            </TableCell>
                            <TableCell
                              className={classes.tableCellClass}
                              style={
                                index % 2
                                  ? { background: "#fff" }
                                  : { background: "rgba(224, 99, 43, 0.25)" }
                              }
                            >
                              {row.mobilenumber ? row.mobilenumber : "-"}
                            </TableCell>
                            <TableCell
                              className={classes.tableCellClass}
                              style={
                                index % 2
                                  ? { background: "#fff" }
                                  : { background: "rgba(224, 99, 43, 0.25)" }
                              }
                            >
                              <MenuOptions
                                modalTitle={"Add Bonus Points for Customer"}
                                userInfo={row}
                                menuOptions={["Add Bonus Points", "Block User"]}
                                getUsers={getUsers}
                                getBlockedVendors={getBlockedUsers}
                              />
                            </TableCell>
                          </ExpandableTableRow>
                        </>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : (
            <Grid
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                height: 500,
                width: 500,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={noDataImg} height={300} width={300} />
              <Typography
                className={classes.typography32}
                style={{ textAlign: "center", margin: "1em" }}
              >
                No Users Found
              </Typography>
            </Grid>
          )}

          {blockedUsers && blockedUsers.length > 0 && (
            <Grid
              className={classes.outerContainer}
              style={{ minHeight: 600, position: "relative" }}
            >
              <Grid
                container
                style={{ justifyContent: "space-between", marginBottom: 7 }}
              >
                <Grid item>
                  <Typography className={classes.typography32}>
                    List of Blocked Users<span style={{  color : "#7e7e7e", fontWeight: 400}}> ({blockedUsers && blockedUsers.length})</span>
                  </Typography>
                </Grid>
                <Grid item>
                  <SearchBar
                    searchBy={blockedSearchBy}
                    setSearchBy={setBlockedSearchBy}
                    menuItems={headings2.slice(1, -1)}
                    keyword={searchBlockedUserTerm}
                    placeholder="Search by email"
                    setKeyword={setSearchBlockedUserTerm}
                  />
                </Grid>
              </Grid>
              <Divider />
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {headings2.map((heading) => (
                        <TableCell
                          className={classes.tableHeaderClass}
                          style={{ borderBottom: "none" }}
                        >
                          {heading}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredBlockUser &&
                      filteredBlockUser.length > 0 &&
                      filteredBlockUser.map((row, index) => (
                        <TableRow
                          style={
                            index % 2
                              ? { background: "#fff" }
                              : { background: "rgba(224, 99, 43, 0.25)" }
                          }
                          className={classes.typography32}
                        >
                          <TableCell className={classes.tableCellClass}>
                            {row.date_joined
                              ? moment(row.date_joined).format("MMM Do YY")
                              : "-"}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.username ? row.username : "-"}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.email ? row.email : "-"}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.address ? row.address : "-"}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.mobilenumber ? row.mobilenumber : "-"}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            <MenuOptions
                              userInfo={row}
                              menuOptions={["Unblock"]}
                              getUsers={getUsers}
                              getBlockedVendors={getBlockedUsers}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.modalClass}>
              <Typography
                className={classes.typography32}
                style={{ padding: 15 }}
              >
                Bulk add bonus points
              </Typography>
              <Divider />

              <Grid style={{ padding: "0px 15px 35px 15px" }}>
                <Typography className={classes.labelName}>
                  Add Points for following users ?
                </Typography>
                <Grid className={classes.emailScroll}>
                  {bulkPoints && bulkPoints.length > 0 ? (
                    bulkPoints.map((email) => (
                      <Typography
                        className={classes.typography14}
                        style={{ borderBottom: "1px solid #ccc", padding: 5 }}
                      >
                        {email}
                      </Typography>
                    ))
                  ) : (
                    <Typography className={classes.typography14}>
                      No Users selected
                    </Typography>
                  )}
                </Grid>{" "}
                <Grid style={{ marginTop: 15 }}>
                  <Typography className={classes.labelName}>
                    Enter the points to be added
                  </Typography>
                  <CustomTextField
                    fullWidth={false}
                    type="number"
                    name="bonuspoint"
                    autoComplete="off"
                    autoFocus
                    BoxHeight="68px"
                    placeholder="Eg: 25"
                    handleChange={(e) => setBonusPoint(e.target.value)}
                  />
                  <Button className={classes.Btn} onClick={addPoints}>
                    Add Points
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(Users);
