export default {
  outerContainer: {
    height: "auto",
    // width : "50%",
    padding: 20,
    background: "#FFFFFF",
    margin: "20px",
    borderRadius: 12,
    fontFamily: "Ubuntu",
    boxShadow: `inset 0 0 15px rgba(55, 84, 170,0),
        inset 0 0 20px rgba(255, 255, 255,0),
        7px 7px 15px rgba(55, 84, 170,.15),
        -7px -7px 20px rgba(255, 255, 255,1),
        inset 0px 0px 4px rgba(255, 255, 255,.2)`,
  },

  typography32: {
    fontSize: 20,
    color: "#26184D",
    fontWeight: 600,
    fontFamily: "Ubuntu",
  },

  typography14: {
    fontSize: 13,
    color: "#26184D",
    fontWeight: 400,
    fontFamily: "Ubuntu",
    fontStyle: "normal",
  },

  Btn: {
    // marginLeft : 130,
    textTransform: "capitalize",
    fontFamily: "Ubuntu",
    fontSize: 13,
    color: "#26184D",
    fontWeight: 500,
    background: "#E0632B",
    padding: "7px 10px",
    borderRadius: 10,
  },

  tableContainer: {
    height: "500px",
    margin: "20px 0",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },

  tableHeaderClass: {
    fontSize: 16,
    color: "#26184D",
    fontWeight: 600,
    fontFamily: "Ubuntu",
    border: "none",
    background: "#fff",
    // textAlign : "center",
    padding: 20,
    // borderRadius: 10
  },

  tableCellClass: {
    fontSize: 14,
    color: "#5c5c5c",
    fontWeight: 400,
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    letterSpacing: 0.4,
    padding: 20,
    borderBottom: "none",
  },

  tableRowClass: {
    "&:hover": {
      background: "rgba(224, 99, 43, 0.15)",
    },
  },
  approvedStatus: {
    color: "#FFF",
    background: "#026c45",
    padding: "5px 15px",
    borderRadius: 6,
  },
  onHoldStatus: {
    color: "#FFF",
    background: "#be0000",
    padding: "5px 15px",
    borderRadius: 6,
  },
};
