import React, { useState, useEffect } from "react";
import styles from "./payments.style";
import {
  Button,
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Divider,
  Modal,
  Box,
  Snackbar,
  withStyles,
} from "@material-ui/core";
import Card from "../customStyle/cards";
import { URLS } from "../../constants/index";
import { allRequestHandler } from "../../api";
import noDataImg from "../../assets/search.png";
import CustomTextField from "../customStyle/textfield";
import MuiAlert from "@material-ui/lab/Alert";
import { Redirect } from "react-router-dom";
import Spinner from "../customStyle/spinner";
import SearchBar from "../customStyle/searchBar";
import moment from 'moment'
const Payments = ({ classes }) => {
  useEffect(() => {
    getPaymentsData();
    getUserDetails();
  }, []);

  const headings = ["Requested on", "Amount Requested", "Status"];
  const [userData, setUserData] = useState(null);

  const [paymentHistory, setPaymentHistoryData] = useState(null);
  const [balanceData, setBalanceData] = useState(null);
  const [hasBankDetails, setHasBankDetails] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [withdrawalAmount, setwithdrawalAmount] = useState(null);
  const [handleSnackbar, setHandleSnackbar] = useState(null);
  const [displayMsg, setDisplayMsg] = useState(null);
  const [severity, setSeverity] = useState("");
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("")
  const getPaymentsData = async () => {
    const customerEmail = sessionStorage.getItem("customerEmail");
    let paymentsData = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.PAYMENTS + `?status=False`,
    });

    let approvedPayments = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.PAYMENTS + `?status=True`,
    });
    if (paymentsData.status == "401") setRedirect(true);
    else {
      setLoading(false);
      setPaymentHistoryData([
        ...approvedPayments.withdrawals,
        ...paymentsData.withdrawals,
      ]);
      setBalanceData(paymentsData.balance_data);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const showModal = () => {
    setOpenModal(true);
  };
  const withdrawAmount = async () => {
    const customerEmail = sessionStorage.getItem("customerEmail");
    let sendforApproval = await allRequestHandler({
      requestType: "POST",
      requestUrl: URLS.WITHDRAW,
      requestData: {
        customer_email: customerEmail,
        amount_requested: withdrawalAmount,
      },
    });
    console.log(sendforApproval);
    if (withdrawalAmount !== "") {
      if (sendforApproval.response == "Request created") {
        setDisplayMsg("Withdrawal request sent!");
        setHandleSnackbar(true);
        setSeverity("success");
        getPaymentsData();
      } else {
        setDisplayMsg(sendforApproval.data.message);
        setHandleSnackbar(true);
        setSeverity("error");
      }
    } else {
      setDisplayMsg("Please enter some amount value");
      setHandleSnackbar(true);
      setSeverity("error");
    }

    handleCloseModal();
  };

  const getUserDetails = async () => {
    let custUserDetails = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.CUSTOMERDASHBOARD,
    });
    if (custUserDetails.status == "401") setRedirect(true);
    else {
      setLoading(false);
      setUserData(custUserDetails.data);
    }

    if (
      custUserDetails.data.bank_account &&
      custUserDetails.data.bank_name &&
      custUserDetails.data.ifsc_code
    )
      setHasBankDetails(false);
    else setHasBankDetails(true);
  };


  // const filteredPaymentHistory =
  // paymentHistory &&
  // paymentHistory.length > 0 &&
  // paymentHistory.filter((payment) => {
  //   return payment.amount_requested == searchTerm;
  // });
  if (redirect) return <Redirect push to="/customer/login" />;

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            open={handleSnackbar}
            onClose={() => setHandleSnackbar(false)}
          >
            <MuiAlert elevation={6} variant="standard" severity={severity}>
              {" "}
              {displayMsg}{" "}
            </MuiAlert>
          </Snackbar>
          <Grid
            style={{ display: "flex", flexDirection: "column", padding: 30 }}
          >
            <Grid>
              <Typography className={classes.typography32}>
                My Wallet
              </Typography>
            </Grid>
            {hasBankDetails && (
              <Grid style={{ margin: 15 }}>
                <MuiAlert
                  elevation={3}
                  variant="standard"
                  color="warning"
                  severity="warning"
                >
                  {" "}
                  Please enter your bank details to withdraw any amount{" "}
                </MuiAlert>
              </Grid>
            )}
            <Grid
              className={classes.whiteCard}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                className={classes.typography32}
                style={{ fontSize: 16, marginTop: 10 }}
              >
                Total Amount:{" "}
                {balanceData &&
                  Number(balanceData.amount_available)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Typography>
              <Typography
                className={classes.Btn}
                onClick={showModal}
                style={
                  hasBankDetails
                    ? { opacity: 0.5, cursor: "not-allowed",
                     pointerEvents: "none"
                     }
                    : { opacity: 1, cursor: "pointer" }
                }
              >
                Withdraw
              </Typography>
            </Grid>

            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "55%",
                margin: "0 auto",
              }}
            >
              <Card
                currency={"₹"}
                title={"Pending"}
                amount={balanceData && balanceData.amount_pending}
                style={{
                  width: "200px",
                  alignItems: "center",
                }}
              />
              <Card
                currency={"₹"}
                title={"Balance"}
                amount={balanceData && balanceData.amount_available}
                style={{
                  width: "200px",
                  alignItems: "center",
                }}
              />
            </Grid>

            <Grid
              className={classes.whiteCard}
              style={{ padding: 20, position: "relative", height: 400 }}
            >
              {/* <Grid container style={{ justifyContent: "space-between", marginBottom : 7 }}>
                <Grid item> */}
                  <Typography className={classes.typography32}>
                  Payment History
                  </Typography>
                {/* </Grid>
                <Grid item>
                  <SearchBar 
                    keyword={searchTerm}
                    placeholder="Search by amount"
                    setKeyword={setSearchTerm}
                  />
                </Grid>
              </Grid> */}
              <Divider />
              {paymentHistory &&  paymentHistory.length > 0 ? (
                <TableContainer className={classes.tableContainer}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead style={{ background: "none" }}>
                      {headings.map((heading) => (
                        <TableCell
                          style={{ borderBottom: "none", fontSize: 16 }}
                          className={classes.typography32}
                        >
                          {heading}
                        </TableCell>
                      ))}
                    </TableHead>
                    <TableBody>
                      {paymentHistory &&
                       paymentHistory.length > 0 &&
                      paymentHistory.map((row, index) => (
                          <TableRow
                            style={
                              index % 2
                                ? { background: "#fff" }
                                : { background: "#f0cdbd" }
                            }
                            className={classes.typography32}
                          >
                            <TableCell
                              style={{ borderBottom: "none" }}
                              className={classes.typography14}
                            >
                               {moment(row.request_on).format("MMM Do YY")}
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none" }}
                              className={classes.typography14}
                            >
                              {row.amount_requested
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none" }}
                              className={classes.typography14}
                            >
                              {row.status == true ? (
                                <span className={classes.approvedStatus}>
                                  Approved
                                </span>
                              ) : (
                                <span className={classes.onHoldStatus}>
                                  On hold
                                </span>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Grid
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: 500,
                    width: 500,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={noDataImg} height={100} width={100} />
                  <Typography
                    className={classes.typography32}
                    style={{ textAlign: "center", margin: "1em" }}
                  >
                    No Payment History
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.modalClass}>
              <Typography
                className={classes.typography32}
                style={{ padding: 15 }}
              >
                Withdraw
              </Typography>
              <Divider />

              <Grid style={{ padding: "0px 15px 35px 15px" }}>
                <Typography className={classes.labelName}>
                  Enter the amount to be withdrawn
                </Typography>
                <CustomTextField
                  // fullWidth = {false}
                  type="number"
                  name="bonuspoint"
                  autoComplete="off"
                  autoFocus
                  BoxHeight="68px"
                  placeholder="Eg: 3000"
                  handleChange={(e) => setwithdrawalAmount(e.target.value)}
                />
                <Typography className={classes.typography14}>
                  *All payments will be processed and approved by the Admin.
                </Typography>
                <Button
                  className={classes.Btn}
                  style={{ margin: "15px auto" }}
                  onClick={withdrawAmount}
                >
                  Send for Approval
                </Button>
              </Grid>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(Payments);
