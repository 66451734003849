import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Snackbar,
  withStyles,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import airprobeLogo from "../../assets/logo.svg";
import CustomTextField from "../customStyle/textfield";
import styles from "./registerUser.style";
import { URLS } from "../../constants/index";
import { allRequestHandler } from "../../api";
import { useHistory, Link, Redirect } from "react-router-dom";

const Register = ({ classes }) => {
  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [deposit, setDeposit] = useState(null);
  const [email, setEmail] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [handleSnackbar, setHandleSnackbar] = useState(null);
  const [displayMsg, setDisplayMsg] = useState(null);
  const [btnToggle, setBtnToggle] = useState(false);

  const [gstin, setGstin] = useState(null);
  const [cin, setCin] = useState(null);
  const [taxRate, setTaxRate] = useState(null);

  const history = useHistory();

  const handleClick = () => {
    history.push("/dashboard");
  };

  const signUp = async (e) => {
    e.preventDefault();
    if (email !== "" && password !== "" && password === confirmPassword) {
      let getToken = await allRequestHandler({
        requestType: "REGISTER",
        requestUrl: URLS.REGISTER,
        requestData: {
          username: userName,
          password: password,
          confirm_password: confirmPassword,
          role: "vendor",
          email: email,
          debited_advance: deposit,
          gstin: gstin,
          cin: cin,
          taxrate: taxRate,
        },
      });

      console.log(getToken);

      if (getToken.status == 200) {
        localStorage.setItem("registerData", JSON.stringify(getToken));
        sessionStorage.setItem("jwtToken", getToken.data.token);
        setRedirect(true);
      } else {
        setDisplayMsg(getToken.data.email);
        setHandleSnackbar(true);
        setBtnToggle(true);
        setRedirect(false);
      }
    } else {
      if (password !== confirmPassword) setDisplayMsg("Passwords do not match");
      else setDisplayMsg("Invalid Inputs");
      setHandleSnackbar(true);
      setBtnToggle(true);
      setRedirect(false);
    }
  };

  if (redirect) {
    return <Redirect push to="/vendor/dashboard" />;
  }
  return (
    <>
      <Grid className={classes.outerLogin}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          open={handleSnackbar}
          onClose={() => setHandleSnackbar(false)}
        >
          <MuiAlert elevation={6} variant="standard" severity="error">
            {" "}
            {displayMsg}{" "}
          </MuiAlert>
        </Snackbar>
        <Grid className={classes.leftSection}>
          <Grid className={classes.companyLogo}>
            FOBiz
            <Typography
              style={{
                color: "#fff",
                padding: "10px 20px",
                background: "#E0632B",
                borderRadius: 8,
                fontFamily: "Ubuntu",
              }}
            >
              Vendor Account Registration
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.loginBox}>
          <Typography
            style={{
              fontSize: 35,
              fontFamily: "Ubuntu",
              fontWeight: 600,
              margin: 10,
            }}
          >
            Register Now
          </Typography>

          <Grid className={classes.login}>
            <form onSubmit={(e) => signUp(e)}>
              <div style={{ marginBottom: 20 }}>
                <Typography className={classes.labelName}>Name</Typography>
                <CustomTextField
                  id="username"
                  name="username"
                  autoComplete="off"
                  autoFocus
                  BoxHeight={"38px"}
                  handleChange={(e) => setUserName(e.target.value)}
                />
              </div>

              <div style={{ marginBottom: 20 }}>
                <Typography className={classes.labelName}>Email</Typography>
                <CustomTextField
                  id="email"
                  name="email"
                  autoComplete="off"
                  autoFocus
                  BoxHeight={"38px"}
                  handleChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <div style={{ margin: "10px 10px 20px 0" }}>
                  <Typography className={classes.labelName}>
                    Deposit Amount
                  </Typography>
                  <CustomTextField
                    id="deposit"
                    name="deposit"
                    autoComplete="off"
                    autoFocus
                    BoxHeight={"38px"}
                    handleChange={(e) => setDeposit(e.target.value)}
                  />
                </div>

                <div style={{ margin: "10px 0px 20px 10px" }}>
                  <Typography className={classes.labelName}>
                    Tax Rate
                  </Typography>
                  <CustomTextField
                    id="taxrate"
                    name="taxrate"
                    autoComplete="off"
                    autoFocus
                    BoxHeight={"38px"}
                    handleChange={(e) => setTaxRate(e.target.value)}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                }}
              >
                <div style={{ margin: "10px 10px 20px 0" }}>
                  <Typography className={classes.labelName}>
                    GSTin Number
                  </Typography>
                  <CustomTextField
                    id="gstin"
                    name="gstin"
                    type="text"
                    autoComplete="off"
                    autoFocus
                    BoxHeight={"38px"}
                    handleChange={(e) => setGstin(e.target.value)}
                  />
                </div>
                <div style={{ margin: "10px 0 20px 10px" }}>
                  <Typography className={classes.labelName}>
                    Cin Number
                  </Typography>
                  <CustomTextField
                    id="cin"
                    name="cin"
                    autoComplete="off"
                    autoFocus
                    BoxHeight={"38px"}
                    handleChange={(e) => setCin(e.target.value)}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                }}
              >
                <div style={{ margin: "0px 10px 20px 0" }}>
                  <Typography className={classes.labelName}>
                    Password
                  </Typography>
                  <CustomTextField
                    name="password"
                    id="password"
                    type="password"
                    autoComplete="current-password"
                    BoxHeight={"38px"}
                    handleChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div style={{ margin: "0px 0 20px 10px" }}>
                  <Typography className={classes.labelName}>
                    Confirm Password
                  </Typography>
                  <CustomTextField
                    name="confirmpassword"
                    id="confirmpassword"
                    type="password"
                    autoComplete="current-password"
                    BoxHeight={"38px"}
                    handleChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.loginBtn}
              >
                Register
              </Button>
            </form>
          </Grid>
          <Typography className={classes.labelName}>
            Already have an account ?
            <Link to="/vendor/login">
              <span
                style={{
                  color: "#346BD4",
                  fontSize: 14,
                  fontFamily: "Ubuntu",
                  margin: "5px 5px 0",
                  textDecoration: "none",
                }}
              >
                Sign in
              </span>
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(Register);
