import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./salesApproval.style";
import {
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  InputLabel, 
  Divider,
} from "@material-ui/core";
import { URLS } from "../../../constants/index";
import { allRequestHandler } from "../../../api";
import MenuOptions from "../menuOptions";
import noDataImg from "../../../assets/search.png";
import { Redirect } from "react-router-dom";
import Spinner from "../../customStyle/spinner";
import SearchBar from "../../customStyle/searchBar";

import moment from "moment";
const headings = [
  "Date",
  "Vendor Email",
  "Place/Area",
  "Amount",
  "Service",
  "Description",
  "",
];

const SalesApproval = ({ classes }) => {
  const [salesForApproval, setSalesForApproval] = useState(null);
  const [approvedSales, setApprovedSales] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchOnHoldSalesTerm, setSearchOnHoldSalesTerm] = useState("");
  const [searchApprovedSalesTerm, setSearchApprovedSalesTerm] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [approvedSearchBy, setApprovedSearchBy] = useState("");
  useEffect(() => {
    getSalesForApproval();
  }, []);

  const getSalesForApproval = async () => {
    let onHoldsalesData = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.GETSALES + "?status=false",
      //  "?userid=" + sessionStorage.getItem("userId"),
    });

    let approvedSales = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.GETSALES + "?status=true",
      //  "?userid=" + sessionStorage.getItem("userId"),
    });

    if (onHoldsalesData.status == "401" || approvedSales.status == "401")
      setRedirect(true);
    else {
      setLoading(false);
      setSalesForApproval(onHoldsalesData);
      setApprovedSales(approvedSales);
    }
  };

  const filteredApprovedSales =
  approvedSearchBy == "Vendor Email"
  ?
    approvedSales &&
    approvedSales.length > 0 &&
    approvedSales.filter((sale) => {
      return sale.createdBy
        .toLowerCase()
        .includes(searchApprovedSalesTerm.toLowerCase());
    })
    : approvedSearchBy == "Place/Area"
    ?  approvedSales &&
       approvedSales.length > 0 &&
       approvedSales.filter((sale) => {
        return sale.place
          .toLowerCase()
          .includes(searchApprovedSalesTerm.toLowerCase());
      })
    : approvedSearchBy == "Amount"
    ? approvedSales &&
       approvedSales.length > 0 &&
       approvedSales.filter((sale) => {
        return sale.amount_of_billing
          .toString()
          .toLowerCase()
          .includes(searchApprovedSalesTerm.toString());
      })
    : approvedSearchBy == "Service"
    ? approvedSales &&
      approvedSales.length > 0 &&
      approvedSales.filter((sale) => {
        return sale.service_taken
          .toLowerCase()
          .includes(searchApprovedSalesTerm.toLowerCase());
      })
    : approvedSearchBy == "Description"
    ? approvedSales &&
      approvedSales.length > 0 &&
      approvedSales.filter((sale) => {
        return sale.order_description
          .toLowerCase()
          .includes(searchApprovedSalesTerm.toLowerCase());
      })
    : approvedSales;



  const filteredOnHoldSales =
    searchBy == "Vendor Email"
      ? salesForApproval &&
        salesForApproval.length > 0 &&
        salesForApproval.filter((sale) => {
          return sale.createdBy
            .toLowerCase()
            .includes(searchOnHoldSalesTerm.toLowerCase());
        })
      : searchBy == "Place/Area"
      ? salesForApproval &&
        salesForApproval.length > 0 &&
        salesForApproval.filter((sale) => {
          return sale.place
            .toLowerCase()
            .includes(searchOnHoldSalesTerm.toLowerCase());
        })
      : searchBy == "Amount"
      ? salesForApproval &&
        salesForApproval.length > 0 &&
        salesForApproval.filter((sale) => {
          return sale.amount_of_billing
            .toString()
            .toLowerCase()
            .includes(searchOnHoldSalesTerm.toString());
        })
      : searchBy == "Service"
      ? salesForApproval &&
        salesForApproval.length > 0 &&
        salesForApproval.filter((sale) => {
          return sale.service_taken
            .toLowerCase()
            .includes(searchOnHoldSalesTerm.toLowerCase());
        })
      : searchBy == "Description"
      ? salesForApproval &&
        salesForApproval.length > 0 &&
        salesForApproval.filter((sale) => {
          return sale.order_description
            .toLowerCase()
            .includes(searchOnHoldSalesTerm.toLowerCase());
        })
      : salesForApproval;

  if (redirect) return <Redirect push to="/admin/login" />;

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Grid
            className={classes.outerContainer}
            style={{ position: "relative" }}
          >
            <Grid
              container
              style={{ justifyContent: "space-between", marginBottom: 7 }}
            >
              <Grid item>
                <Typography className={classes.typography32}>
                  Sales for Approval<span style={{  color : "#7e7e7e", fontWeight: 400}}> ({salesForApproval && salesForApproval.length})</span>
                </Typography>
              </Grid>

              <Grid item>
                <SearchBar
                  searchBy={searchBy}
                  setSearchBy={setSearchBy}
                  menuItems={headings.slice(1, -1)}
                  keyword={searchOnHoldSalesTerm}
                  placeholder="Search"
                  setKeyword={setSearchOnHoldSalesTerm}
                />
              </Grid>
            </Grid>
            <Divider />
            {salesForApproval && salesForApproval.length > 0 ? (
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {headings.map((heading) => (
                        <TableCell
                          style={{ borderBottom: "none" }}
                          className={classes.tableHeaderClass}
                        >
                          {heading}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredOnHoldSales &&
                      filteredOnHoldSales.length > 0 &&
                      filteredOnHoldSales.map((row, index) => (
                        <TableRow
                          style={
                            index % 2
                              ? { background: "#fff" }
                              : { background: "rgba(224, 99, 43, 0.25)" }
                          }
                          className={classes.typography32}
                        >
                          <TableCell className={classes.tableCellClass}>
                            {row.date
                              ? moment(row.date).format("MMM Do YY")
                              : "-"}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.createdBy}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.place}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.amount_of_billing
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.service_taken}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.order_description}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            <MenuOptions
                              //  onClickApprove = {()=> approveSale(row.id)}
                              menuOptions={["Approve Sale"]}
                              approveSaleId={row.id}
                              getSalesForApproval={getSalesForApproval}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Grid
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  height: 500,
                  width: 500,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={noDataImg} height={200} width={200} />
                <Typography
                  className={classes.typography32}
                  style={{ textAlign: "center", margin: "1em" }}
                >
                  No Sales Registered
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid
            className={classes.outerContainer}
            style={{ position: "relative" }}
          >
            <Grid
              container
              style={{ justifyContent: "space-between", marginBottom: 7 }}
            >
              <Grid item>
                <Typography className={classes.typography32}>
                  Approved Sales<span style={{  color : "#7e7e7e", fontWeight: 400}}> ({approvedSales && approvedSales.length})</span>
                </Typography>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <SearchBar
                      searchBy={approvedSearchBy}
                      setSearchBy={setApprovedSearchBy}
                      menuItems={headings.slice(1, -1)}
                      keyword={searchApprovedSalesTerm}
                      placeholder="Search by email"
                      setKeyword={setSearchApprovedSalesTerm}
                    />
                  </Grid>
                  <Grid item>
                    <a
                      href="https://api.fobiz.net/v1/api/excel/sales"
                      style={{ textDecoration: "none" }}
                    >
                      {" "}
                      <Button
                        className={classes.Btn}
                        style={{ padding: "2px 10px", margin: "0px 5px" }}
                      >
                        Export to excel
                      </Button>{" "}
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            {approvedSales && approvedSales.length > 0 ? (
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {headings.map((heading) => (
                        <TableCell
                          style={{ borderBottom: "none" }}
                          className={classes.tableHeaderClass}
                        >
                          {heading}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredApprovedSales &&
                      filteredApprovedSales.length > 0 &&
                      filteredApprovedSales.map((row, index) => (
                        <TableRow
                          style={
                            index % 2
                              ? { background: "#fff" }
                              : { background: "rgba(224, 99, 43, 0.25)" }
                          }
                          className={classes.typography32}
                        >
                          <TableCell className={classes.tableCellClass}>
                            {row.date
                              ? moment(row.date).format("MMM Do YY")
                              : "-"}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.createdBy}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.place}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.amount_of_billing
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.service_taken}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            {row.order_description}
                          </TableCell>
                          <TableCell className={classes.tableCellClass}>
                            <span className={classes.approvedStatus}>
                              Approved
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Grid
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  height: 500,
                  width: 500,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={noDataImg} height={200} width={200} />
                <Typography
                  className={classes.typography32}
                  style={{ textAlign: "center", margin: "1em" }}
                >
                  No Sales approved yet
                </Typography>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(SalesApproval);
