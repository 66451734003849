 const initState = {
     userData : { }
 }

 const rootReducer = (state = initState, action) => {
     if(action.type == "SETUSERDATA") {
         return {
            ...state,
             userData : action.userData
         }
     }
     return state;
 }

 export default rootReducer