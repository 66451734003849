
import facebookImg  from "../../assets/facebook.svg"
import  instaImg from "../../assets/instagram.svg"
import twitterImg from "../../assets/twitter.svg"
import telegramImg from "../../assets/telegram.svg"
import whatsappImg from "../../assets/whatsapp.svg"

export const icons = [
    {
        icon : facebookImg,
        name : "Facebook",
        url : "https://www.facebook.com"
    },
    {
     icon : instaImg,
     name : "Instagram",
     url : "https://www.instagram.com/"  
    },
    {
        icon : twitterImg,
        name : "Twitter",
        url : "https://twitter.com/home"
    },
    {
        icon : telegramImg,
        name : "Telegram",
        url : "https://web.telegram.org/k"
    },
    {
        icon : whatsappImg,
        name : "whatsapp",
        url : "https://web.whatsapp.com"
    }
]