import React, { useState, useEffect } from "react";
import styles from "./orders.style";
import { Button, Grid, Typography, withStyles } from "@material-ui/core";
import { URLS } from "../../constants/index";
import { allRequestHandler } from "../../api";
import noDataImg from "../../assets/nodata.svg";
import { Redirect } from "react-router-dom";
import SearchBar from "../customStyle/searchBar";
import Spinner from "../customStyle/spinner";
import moment from 'moment'
const Orders = ({ classes }) => {
  useEffect(() => {
    getOrderData();
  }, []); 

  const [orderData, setOrderData] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("")
  const [searchBy, setSearchBy] = useState("");
  const getOrderData = async () => {
    const userid = sessionStorage.getItem("userId");
    const customerEmail = sessionStorage.getItem("customerEmail");
    let ordersData = await allRequestHandler({
      requestType: "GET",
      requestUrl: URLS.GETSALES + `?tocust=${customerEmail}&status=true`,
    });

    if (ordersData) {
      setLoading(false);
      setOrderData(ordersData);
    }
  };

  const filteredOrders =
  searchBy == 'Vendor Email' ? 
    orderData &&
    orderData.length > 0 &&
    orderData.filter((sale) => {
      return sale.createdBy.toLowerCase().includes(searchTerm.toLowerCase());
    }) 
    :
    searchBy == "Order Description" ? 
    orderData &&
    orderData.length > 0 &&
    orderData.filter((sale) => {
      return sale.order_description.toLowerCase().includes(searchTerm.toLowerCase());
    }) 
    :
    searchBy ==  "Amount" ? 
    orderData &&
    orderData.length > 0 &&
    orderData.filter((sale) => {
      return sale.amount_of_billing.toString().includes(searchTerm.toString());
    }) 
    :
    searchBy == "Service Taken" ? 
    orderData &&
    orderData.length > 0 &&
    orderData.filter((sale) => {
      return sale.service_taken.toLowerCase().includes(searchTerm.toLowerCase());
    }) 
    :
    orderData

  if (redirect) return <Redirect push to="/customer/login" />;

  return (
    <>
      {orderData && orderData.length > 0 ? (
        <Grid style={{ display: "flex", flexDirection: "column", padding: 30 }}>
         <Grid container style={{ justifyContent: "space-between", marginBottom : 7 }}>
                <Grid item>
                  <Typography className={classes.typography32}>
                    My Orders
                  </Typography>
                </Grid>
                <Grid item>
                  <SearchBar 
                  searchBy = {searchBy}
                  setSearchBy = {setSearchBy}
                  menuItems = {['Service Taken', 'Order Description', 'Amount', 'Vendor Email']}
                    keyword={searchTerm}
                    placeholder="Search by vendor email"
                    setKeyword={setSearchTerm}
                  />
                </Grid>
              </Grid>
          <Grid
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gridGap: 15,
            }}
          >
            {filteredOrders && filteredOrders.length > 0 && filteredOrders.map((order) => (
              <Grid
                className={classes.orderCard}
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2,1fr)",
                  gridGap: 15,
                }}
              >
                <Typography className={classes.typography20}>Date</Typography>

                <Typography className={classes.descTypo}>
                  {" "}
                  {moment(order.date).format("MMM Do YY")}
                </Typography>

                <Typography className={classes.typography20}>
                  Service
                </Typography>
                <Typography className={classes.descTypo}>
                  {order.service_taken}
                </Typography>

                <Typography className={classes.typography20}>
                  Description
                </Typography>
                <Typography className={classes.descTypo}>
                  {order.order_description}
                </Typography>

                <Typography className={classes.typography20}>Amount</Typography>
                <Typography className={classes.descTypo}>
                  {order.amount_of_billing
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>

                <Typography className={classes.typography20}>
                  Vendor Email
                </Typography>

                <Typography className={classes.descTypo}>
                  {order.createdBy}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Grid
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: 500,
            width: 500,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={noDataImg} />
          <Typography
            className={classes.typography32}
            style={{ color: "#E0632B", textAlign: "center", margin: "1em" }}
          >
            No Orders Found
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default withStyles(styles)(Orders);
