import React from "react";
import {
  IconButton,
  Grid,
  InputBase,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "../../assets/search.svg";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  outerSearch: {
    width: "300px",
    height: "35px",
    margin: "0 12px",
    background: "#FFFFFF",
    border: "1px solid #DDE5EC",
    borderRadius: "8px",
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
  },
  iconBtn: {
    marginRight: 5,
    marginLeft: "10px",
    width: "10px",
    height: "16px",
    color: "#A8B9C6",
    alignSelf: "center",
    marginTop: 5,
  },
  inputBase: {
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    border: "none",
    padding: "8px",
    width: "83%",
    paddingBottom: "3px",
  },
  searchByInput: {
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 500,
    color: "#7e7e7e",
    fontSize: "14px",
  },
});

const searchBar = (props) => {
  const { classes } = props;
  return (
    <Grid container>
      <Grid item>
        <FormControl
          variant="standard"
          fullWidth
          style={{ width: 200, marginTop: -15, marginRight :  15 }}
        >
          <InputLabel
            id="demo-simple-select-label"
            classes={{
              root: classes.searchByInput,
            }}
          >
            Search By
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.searchBy}
            label="Search By"
            classes={{
              root: classes.searchByInput,
            }}
            onChange={(e) => props.setSearchBy(e.target.value)}
          >
            {props.menuItems &&
              props.menuItems.map((item) => (
                <MenuItem value={item}
                classes={{
                  root: classes.searchByInput,
                }}
                >{item}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      {props.searchBy && (
        <Grid item className={classes.outerSearch}>
          <IconButton
            disabled
            type="submit"
            aria-label="search"
            size="small"
            variant="text"
            className={classes.iconBtn}
          >
            <span>
              <img src={SearchIcon} />
            </span>
          </IconButton>
          <InputBase
            value={props.keyword}
            classes={{
              root: classes.inputBase,
            }}
            name="input"
            onChange={(e) => props.setKeyword(e.target.value)}
            placeholder={`Search ${props.searchBy}`}
          />
          {props.keyword !== null && props.keyword !== "" && (
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                props.setKeyword("");
              }}
              style={{ padding: 8, outline: "none" }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(searchBar);
